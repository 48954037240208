import styles from '@styles/components/form.module.scss';
import { FC } from 'react';
import { COLORS } from '../../v3/global';

const FormButton: FC<{
  title?: string;
  onClick?: () => void;
  style?: any;
  className?: any;
}> = ({ children, title, onClick, style, className = '' }) => {
  return (
    <div className={styles.formInput}>
      {title && <label style={{ color: COLORS.TEXT_PRIMARY }}>{title}</label>}
      <div
        className={`${styles.formButton} ${className}`}
        onClick={onClick}
        style={style}
      >
        {children}
      </div>
    </div>
  );
};

export default FormButton;
