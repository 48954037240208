import defaultAvatar from '@assets/default-user.png';
import FormButton from '@components/Form/FormButton';
import LockedFormInput from '@components/Form/LockedFormInput';
import CustomCourseSelector from '@components/Selectors/CustomCourseSelector';
import CustomStudySubjectsSelector from '@components/Selectors/CustomStudySubjectsSelector';
import { LockClosedIcon, PencilIcon } from '@heroicons/react/outline';
import styles from '@styles/pages/mentor/settings.module.scss';
import { Popconfirm, Avatar, Rate } from 'antd';
import { COLORS } from '../../../v3/global';

const MentorBaseSettings = ({
  avatar,
  name,
  uploadFn,
  username,
  changePasswordFn,
  gender,
  setGender,
  country,
  setCountry,
  pronouns,
  setPronouns,
  email,
  phoneNumber,
  universities,
  setUniversities,
  saveAll,
  mentorData,
  setMajors,
  majors,
  setPhoneNumber,
  studySubjects,
  setStudySubjects,
  coursesCanTeach,
  setCoursesCanTeach,
  type,
  setIsFormChanged,
  saveCourses,
}: any) => {
  return (
    <div
      style={{
        backgroundColor: COLORS.COMPONENT_BACKGROUND,
      }}
      className={styles.section}
    >
      <div className={styles.base}>
        <div className={styles.baseHeader}>
          <div className={styles.baseHeaderAvatarWrapper}>
            <Avatar
              src={avatar || defaultAvatar}
              className={styles.baseHeaderAvatar}
              crossOrigin={'anonymous'}
            />

            <input
              type="file"
              className={styles.baseHeaderInput}
              onChange={uploadFn}
            />

            <div className={styles.baseHeaderAvatarButton}>
              <PencilIcon width={25} height={25} />
            </div>
          </div>

          <div
            style={{ color: COLORS.TEXT_PRIMARY }}
            className={styles.baseHeaderName}
          >
            {name}
          </div>
          <div
            style={{ color: COLORS.TEXT_PRIMARY }}
            className={styles.baseHeaderEmail}
          >
            {email}
          </div>

          {/*TODO Remove if no longer needed*/}
          {/*<div className={styles.baseHeaderFeedback}>*/}
          {/*  <Rate*/}
          {/*    allowHalf*/}
          {/*    disabled*/}
          {/*    value={Math.ceil(mentorData?.averageFeedback)}*/}
          {/*  />*/}
          {/*  {mentorData?.averageFeedback?.toFixed(2) || '...'}/5*/}
          {/*</div>*/}
        </div>
      </div>

      <div className={styles.baseBlock}>
        <div
          style={{ color: COLORS.TEXT_PRIMARY }}
          className={styles.baseBlockTitle}
        >
          Basic Info
        </div>

        <LockedFormInput
          title="University"
          value={universities[0] || ''}
          onChange={(e) => {
            setUniversities([e.target.value]);
            setIsFormChanged(true);
          }}
        />

        <LockedFormInput
          title="Field of study"
          value={studySubjects[0] || ''}
          onChange={(e) => {
            setStudySubjects([e.target.value]);
            setIsFormChanged(true);
          }}
        />

        <LockedFormInput
          title="Phone"
          value={phoneNumber || ''}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
            setIsFormChanged(true);
          }}
        />
      </div>

      <div className={styles.baseBlock}>
        <div
          style={{ color: COLORS.TEXT_PRIMARY }}
          className={styles.baseBlockTitle}
        >
          Subjects can teach
        </div>
        <CustomStudySubjectsSelector
          majors={majors}
          setMajors={setMajors}
          saveCourses={saveCourses}
        />
      </div>

      <div className={styles.baseBlock}>
        <div
          style={{ color: COLORS.TEXT_PRIMARY }}
          className={styles.baseBlockTitle}
        >
          Courses can teach
        </div>
        <CustomCourseSelector
          coursesCanTeach={coursesCanTeach}
          setCoursesCanTeach={setCoursesCanTeach}
          setIsFormChanged={setIsFormChanged}
        />
      </div>

      <div className={styles.baseBlock}>
        <Popconfirm
          placement="bottomLeft"
          title="You will be logged out and taken to the Log In page. There you can choose 'Forgot Password'. Continue?"
          onConfirm={changePasswordFn}
          okText="Yes"
          cancelText="No"
        >
          <div>
            <FormButton title="Password">
              <LockClosedIcon style={{ width: 20, height: 20 }} />
              <div>Change Password</div>
            </FormButton>
          </div>
        </Popconfirm>
      </div>
    </div>
  );
};

export default MentorBaseSettings;
