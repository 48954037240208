export const unsatisfactoryOptions = [
  { name: 'Homework not attempted', selected: false },
  { name: 'Student not showing interest', selected: false },
  { name: 'Student was late to the meeting', selected: false },
  { name: 'Student not asking for help', selected: false },
  { name: 'Student not asking questions', selected: false },
  {
    name: 'Student is frequently rescheduling our sessions',
    selected: false,
  },
  { name: 'Student forgets about our scheduled sessions', selected: false },
];
export const satisfactoryOptions = [
  { name: 'Homework completed', selected: false },
  { name: 'Student being engaged', selected: false },
  { name: 'Student asking questions', selected: false },
  { name: 'Student showing interest during the session', selected: false },
  { name: 'Student asking for help', selected: false },
  { name: 'Student answering questions', selected: false },
  {
    name: `Student taking mentor's suggestions into account`,
    selected: false,
  },
];
export const excellentOptions = [
  { name: 'Student going the extra mile', selected: false },
  { name: 'Student being highly engaged', selected: false },
  { name: 'Student showing initiative', selected: false },
  { name: 'Student asking very interesting questions', selected: false },
  {
    name: `Student building on top of mentor's suggestions`,
    selected: false,
  },
  {
    name: 'Student having amazing insights during the session',
    selected: false,
  },
];

export const behindOptions = [
  { name: 'Poor time management', selected: false },
  { name: 'Student needing a lot of support', selected: false },
  { name: 'Student not working indepentently', selected: false },
  { name: 'Student lacking interest', selected: false },
  { name: 'Student being undecided', selected: false },
  {
    name: 'Student in danger of mising an important deadline',
    selected: false,
  },
];

export const ontrackOptions = [
  { name: 'Good time management', selected: false },
  { name: 'Student making good progress', selected: false },
  { name: 'Student completed all the steps so far', selected: false },
  {
    name: 'Student being aware of the application process timeline',
    selected: false,
  },
  {
    name: 'Student working in a timely manner to meet key deadlines',
    selected: false,
  },
];

export const aheadOptions = [
  {
    name: 'Student efficiently planning their tasks to finish things in advance of key deadlines',
    selected: false,
  },
  {
    name: 'Student being calm and on top of things',
    selected: false,
  },
];

export const engagementOptions = [
  { unsatisfactoryOptions },
  { satisfactoryOptions },
  { excellentOptions },
];

export const progressOptions = [
  { behindOptions },
  { ontrackOptions },
  { aheadOptions },
];

export const homeworkOptions = [
  { behindOptions },
  { ontrackOptions },
  { aheadOptions },
];
