import FormButton from '@components/Form/FormButton';
import FormTextArea from '@components/Form/FormTextArea';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  UserAddIcon,
} from '@heroicons/react/outline';
import {
  useAuthState,
  useMinimumMentorCapacity,
  useSetVatPayerStatus,
  useUpdateMentorCapacity,
} from '@shared/react';
import accountStyles from '@styles/pages/account.module.scss';
import styles from '@styles/pages/mentor/settings.module.scss';
import { Checkbox, message } from 'antd';
import { useEffect, useState } from 'react';
import { COLORS } from '../../../v3/global';

const tabs = [
  { name: 'Mentor Profile', href: '#', current: false, key: 'Tab 1' },
  { name: 'Availability', href: '#', current: false, key: 'Tab 2' },
  { name: 'Payments', href: '#', current: false, key: 'Tab 3' },
];

const MentorOtherSettings = ({
  welcomeMessage,
  shortBiography,
  biography,
  maximumActiveEnrollments,
  weeklyHours,
  isVatPayer,
  saveAll,
  setBiography,
  setWelcomeMessage,
  setShortBiography,
  setMaximumActiveEnrollments,
  setWeeklyHours,
  setIsFormChanged,
  isLoading,
}: {
  welcomeMessage: string;
  shortBiography: string;
  biography: string;
  maximumActiveEnrollments: number;
  weeklyHours: number;
  isVatPayer: boolean;
  setWelcomeMessage: (value: string) => void;
  setShortBiography: (value: string) => void;
  setBiography: (value: string) => void;
  setMaximumActiveEnrollments: (value: number) => void;
  setWeeklyHours: (value: number) => void;
  saveAll: () => void;
  setIsFormChanged: (arg1: boolean) => void;
  isLoading: boolean;
}) => {
  const { userId } = useAuthState();
  const {
    data: mentorCapacity,
    isLoading: isCapacityLoading,
    refetch,
  } = useMinimumMentorCapacity();
  const { mutate: updateMentorCapacity } = useUpdateMentorCapacity(userId);

  const [selectedCapacity, setSelectedCapacity] = useState(0);
  const [activeTab, setActiveTab] = useState('Tab 1');

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(' ');
  };

  useEffect(() => {
    setSelectedCapacity(
      mentorCapacity?.currentCapacity ?? mentorCapacity?.activeEnrollments ?? 0
    );
  }, [isCapacityLoading]);

  const [vatPayer, setVatPayer] = useState(isVatPayer);

  useEffect(() => {
    setVatPayer(isVatPayer);
  }, [isVatPayer]);

  const { mutate: setVatPayerStatus } = useSetVatPayerStatus(userId, {
    onSuccess: () => {
      message.success('VAT Payer status updated successfully');
    },
  });

  return (
    <div>
      {/*TAILWIND TABS*/}
      <div className=" mb-4 rounded-t-2xl">
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex" aria-label="Tabs">
              {tabs.map((tab) => (
                <span
                  style={{
                    cursor: 'pointer',
                    color:
                      activeTab === tab?.key
                        ? COLORS.PRIMARY
                        : COLORS.TEXT_SECONDARY,
                    borderColor:
                      activeTab === tab?.key ? COLORS.PRIMARY : 'inherit',
                  }}
                  key={tab?.key}
                  onClick={() => setActiveTab(tab?.key)}
                  className={classNames(
                    tab?.key === activeTab
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'
                  )}
                  aria-current={tab?.current ? 'page' : undefined}
                >
                  {tab?.name}
                </span>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {activeTab === 'Tab 1' && (
        <div
          style={{
            backgroundColor: COLORS.COMPONENT_BACKGROUND,
            marginTop: '-16px',
            paddingTop: '32px',
          }}
          className={styles.tab}
        >
          {!isLoading && (
            <>
              <FormTextArea
                title="Welcome Message"
                value={welcomeMessage}
                error={welcomeMessage.length === 0}
                success={welcomeMessage.length > 0}
                placeholder="This is the first message that automatically gets sent out to each newly assigned student"
                onChange={(e) => {
                  setWelcomeMessage(e.target.value);
                  setIsFormChanged(true);
                }}
              />

              <div>
                <FormTextArea
                  value={shortBiography}
                  maxLength={500}
                  onChange={(e) => {
                    if (e) {
                      setShortBiography(e.target.value);
                      setIsFormChanged(true);
                    }
                  }}
                  title="Short Biography"
                  error={shortBiography.length === 0}
                  success={shortBiography.length > 0}
                  placeholder="Nothing here yet"
                />
                <div style={{ textAlign: 'right' }}>
                  {shortBiography.length} / 500
                </div>
              </div>

              <div>
                <FormTextArea
                  value={biography}
                  maxLength={1000}
                  rows={6}
                  onChange={(e) => {
                    if (e) {
                      setBiography(e.target.value);
                      setIsFormChanged(true);
                    }
                  }}
                  title="Long Biography"
                  placeholder="Nothing here yet"
                  error={biography.length === 0}
                  success={biography.length > 0}
                />
                <div style={{ textAlign: 'right' }}>
                  {biography.length} / 1000
                </div>
              </div>

              <FormButton onClick={() => saveAll()} className={styles.saveBtn}>
                Save All
              </FormButton>
            </>
          )}
        </div>
      )}

      {activeTab === 'Tab 2' && (
        <div
          style={{
            backgroundColor: COLORS.COMPONENT_BACKGROUND,
            marginTop: '-16px',
            paddingTop: '32px',
          }}
          className={styles.tab}
        >
          {!isLoading && (
            <>
              <div
                style={{
                  backgroundColor: COLORS.COMPONENT_BACKGROUND,
                }}
                className={`${accountStyles.checkCard} ${
                  maximumActiveEnrollments === 0
                    ? accountStyles.checkCardError
                    : ''
                }`}
              >
                <h3
                  style={{
                    color: COLORS.TEXT_PRIMARY,
                  }}
                >
                  Maximum Enrollments
                </h3>
                <div className={accountStyles.checkCardItems}>
                  <UserAddIcon
                    style={{
                      color: COLORS.TEXT_PRIMARY,
                    }}
                    className={accountStyles.icon}
                  />
                  <div>
                    <p
                      style={{
                        color: COLORS.TEXT_PRIMARY,
                      }}
                    >
                      How many enrollments can you take at the same time?
                    </p>
                  </div>
                  <div className={accountStyles.checkCardNumber}>
                    <ChevronUpIcon
                      style={{
                        color: COLORS.TEXT_PRIMARY,
                      }}
                      className={accountStyles.icon}
                      onClick={() => {
                        setSelectedCapacity(
                          (prevsState: number) => prevsState + 1
                        );
                      }}
                    />

                    {!isCapacityLoading && (
                      <span
                        style={{
                          fontSize: '16px',
                          textAlign: 'center',
                          color: COLORS.TEXT_PRIMARY,
                        }}
                      >
                        {selectedCapacity}
                      </span>
                    )}

                    <ChevronDownIcon
                      style={{
                        color: COLORS.TEXT_PRIMARY,
                      }}
                      className={accountStyles.icon}
                      onClick={() => {
                        if (selectedCapacity > 0)
                          setSelectedCapacity(
                            (prevsState: number) => prevsState - 1
                          );
                      }}
                    />
                  </div>
                </div>
              </div>

              <FormButton
                onClick={() => {
                  saveAll();
                  updateMentorCapacity({
                    capacity: selectedCapacity,
                  });
                  refetch();
                }}
                className={styles.saveBtn}
              >
                Save All
              </FormButton>
            </>
          )}
        </div>
      )}

      {activeTab === 'Tab 3' && (
        <div
          style={{
            backgroundColor: COLORS.COMPONENT_BACKGROUND,
            marginTop: '-16px',
            paddingTop: '32px',
          }}
          className={styles.tab}
        >
          {!isLoading && (
            <div
              style={{
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
              }}
              className={`${accountStyles.checkCard}`}
            >
              <h3 style={{ color: COLORS.TEXT_PRIMARY }}>
                Mark yourself as VAT Payer
              </h3>
              <div className={accountStyles.checkCardItems}>
                <div>
                  <p
                    style={{
                      color: COLORS.TEXT_PRIMARY,
                    }}
                  >
                    Selecting this option indicates that you are registered as a
                    Value Added Tax (VAT) payer, meaning you are liable to
                    collect and remit VAT on your taxable services as requested
                    by local tax authorities.
                  </p>
                </div>
                <Checkbox
                  checked={vatPayer}
                  onChange={(e) => {
                    setVatPayer(e.target.checked);
                    setVatPayerStatus({ isVatPayer: e.target.checked });
                  }}
                  style={{
                    backgroundColor: COLORS.DARK_GREY,
                    marginLeft: 'auto',
                    color: 'red',
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MentorOtherSettings;
