import styles from '@styles/components/mentor-feedback.module.scss';
import { FC } from 'react';
import { COLORS } from '../../../../../v3/global';

const StepTitle: FC<{ title: string }> = ({ title }) => {
  return (
    <h3
      className={styles.feedbackQuestionTitle}
      style={{
        marginBottom: '1.5rem',
        color: COLORS.TEXT_PRIMARY,
      }}
    >
      {title}
    </h3>
  );
};

export default StepTitle;
