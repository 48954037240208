import MeetingFeedbackFirstScreenMentor from '@assets/images/meeting-feedback-first-screen.png';
import { Meeting } from '@shared/common';
import styles from '@styles/components/mentor-feedback.module.scss';
import SecondaryButton from '@utils/SecondaryButton';
import { COLORS } from '../../../../../v3/global';

type Props = {
  studentName: string;
  increaseStep: () => void;
  meeting: Meeting;
  hideFeedbackModal: () => void;
};

const Step1 = ({ studentName, increaseStep, meeting }: Props) => {
  const FeedbackPage = () => (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        style={{ minHeight: '280px', maxHeight: '280px', marginBottom: '48px' }}
        src={MeetingFeedbackFirstScreenMentor}
        alt="meeting-needing-feedback"
      />
      <div
        style={{
          textAlign: 'center',
          color: COLORS.TEXT_PRIMARY,
        }}
      >
        <h2
          style={{
            fontSize: '2rem',
            color: COLORS.TEXT_PRIMARY,
            marginBottom: '24px',
          }}
          className={styles.meetingNeedingFeedbackMentorStepOneTitle}
        >
          Almost done.. Please provide feedback for the session
        </h2>
        <h4
          style={{ color: COLORS.PRIMARY, marginBottom: '48px' }}
          className={styles.meetingNeedingFeedbackMentorStepOneDescription}
        >
          {meeting.name} - {studentName}
        </h4>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SecondaryButton
          backgroundColor={COLORS.PRIMARY}
          borderColor={COLORS.PRIMARY}
          height="48px"
          width="75%"
          margin="0 0 24px 0"
          onClick={increaseStep}
        >
          <span
            style={{ color: COLORS.BLACK }}
            className={styles.meetingNeedingFeedbackMentorButtonText}
          >
            Leave Feedback and Complete Session
          </span>
        </SecondaryButton>
      </div>
    </div>
  );

  return <FeedbackPage />;
};

export default Step1;
