import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import MentorsTableHeader from '@components/V3/MentorsTableHeader';
import MentorsTableRow from '@components/V3/MentorsTableRow';
import TableColumnSortButtons from '@components/V3/TableColumnSortButtons';
import UsersTableInfo from '@components/V3/UsersTableInfo';
import AgoraSpinner from '@components/V3/Utils/AgoraSpinner';
import { useGetMentorWidgetStatistics, useSearchMentors } from '@shared/react';
import { Pagination } from 'antd';
import { createContext, useState } from 'react';
import { COLORS } from '../../../v3/global';
import './mentors-v3.styles.scss';
import SessionModal, {
  SessionModalPage,
} from '../components/SessionModal/SessionModal';

interface MentorPageSessionModal {
  showEnrollmentPageModalHandler?: (enrollmentId: string) => void;
  showStudentPageModalHandler?: (studentId: string) => void;
  showSessionPageModalHandler?: (sessionId: string) => void;
}

export const MentorPageSessionModal = createContext<MentorPageSessionModal>({
  showEnrollmentPageModalHandler: () =>
    console.warn('handleShowEnrollmentPageModal not implemented'),
  showStudentPageModalHandler: () =>
    console.warn('handleShowEnrollmentPageModal not implemented'),
  showSessionPageModalHandler: () =>
    console.warn('handleShowEnrollmentPageModal not implemented'),
});

const MentorsV3 = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [statuses, setStatuses] = useState<string[]>([]);
  const [course, setCourse] = useState<any>([]);
  const [isTestUser, setIsTestUser] = useState(false);
  const [extraFilter, setExtraFilter] = useState(undefined);

  const [studentId, setStudentId] = useState<string>();
  const [enrollmentId, setEnrollmentId] = useState<string>();
  const [sessionId, setSessionId] = useState<string>();
  const [modalPage, setModalPage] = useState<SessionModalPage>('enrollment');
  const [showSessionModal, setShowSessionModal] = useState(false);

  const [columnsSorting, setColumnsSorting] = useState({
    capacity: 'default',
    maxCapacity: 'default',
    maxCapacityUpdatedAt: 'default',
    payRate: 'default',
    studySubjects: 'default',
    createdAt: 'default',
  });

  const {
    data: mentorStatistics,
    isLoading: isLoadingMentorStatistics,
    refetch: refetchMentorStatistics,
  } = useGetMentorWidgetStatistics();

  const {
    data: mentors,
    isLoading: isLoadingMentors,
    refetch: refetchSearchMentors,
  } = useSearchMentors({
    page: currentPage,
    limit: pageSize,
    search: search,
    statuses: statuses.length !== 0 ? statuses : undefined,
    courses: course,
    isTestUser: isTestUser,
    sorting: { ...columnsSorting, fieldOfStudy: columnsSorting.studySubjects },
    extraFilter: extraFilter,
  });

  const noOfPages = mentors && mentors.total ? mentors?.total / pageSize : 0;
  const pagesArray = [...Array(Math.ceil(noOfPages))];
  const showAllPages = noOfPages <= 7;

  const handleColumnSorting = (column: any) => {
    if (column === 'capacity' && columnsSorting.capacity === 'default') {
      setColumnsSorting({
        capacity: 'asc',
        maxCapacity: 'default',
        maxCapacityUpdatedAt: 'default',
        payRate: 'default',
        studySubjects: 'default',
        createdAt: 'default',
      });
    } else if (column === 'capacity' && columnsSorting.capacity === 'asc') {
      setColumnsSorting({
        capacity: 'desc',
        maxCapacity: 'default',
        maxCapacityUpdatedAt: 'default',
        payRate: 'default',
        studySubjects: 'default',
        createdAt: 'default',
      });
    } else if (
      column === 'maxCapacity' &&
      columnsSorting.maxCapacity === 'default'
    ) {
      setColumnsSorting({
        capacity: 'default',
        maxCapacity: 'asc',
        maxCapacityUpdatedAt: 'default',
        payRate: 'default',
        studySubjects: 'default',
        createdAt: 'default',
      });
    } else if (
      column === 'maxCapacity' &&
      columnsSorting.maxCapacity === 'asc'
    ) {
      setColumnsSorting({
        capacity: 'default',
        maxCapacity: 'desc',
        maxCapacityUpdatedAt: 'default',
        payRate: 'default',
        studySubjects: 'default',
        createdAt: 'default',
      });
    } else if (
      column === 'maxCapacityUpdatedAt' &&
      columnsSorting.maxCapacityUpdatedAt === 'default'
    ) {
      setColumnsSorting({
        capacity: 'default',
        maxCapacity: 'default',
        maxCapacityUpdatedAt: 'asc',
        payRate: 'default',
        studySubjects: 'default',
        createdAt: 'default',
      });
    } else if (
      column === 'maxCapacityUpdatedAt' &&
      columnsSorting.maxCapacityUpdatedAt === 'asc'
    ) {
      setColumnsSorting({
        capacity: 'default',
        maxCapacity: 'default',
        maxCapacityUpdatedAt: 'desc',
        payRate: 'default',
        studySubjects: 'default',
        createdAt: 'default',
      });
    } else if (column === 'payRate' && columnsSorting.payRate === 'default') {
      setColumnsSorting({
        capacity: 'default',
        maxCapacity: 'default',
        maxCapacityUpdatedAt: 'default',
        payRate: 'asc',
        studySubjects: 'default',
        createdAt: 'default',
      });
    } else if (column === 'payRate' && columnsSorting.payRate === 'asc') {
      setColumnsSorting({
        capacity: 'default',
        maxCapacity: 'default',
        maxCapacityUpdatedAt: 'default',
        payRate: 'desc',
        studySubjects: 'default',
        createdAt: 'default',
      });
    } else if (
      column === 'studySubjects' &&
      columnsSorting.studySubjects === 'default'
    ) {
      setColumnsSorting({
        capacity: 'default',
        maxCapacity: 'default',
        maxCapacityUpdatedAt: 'default',
        payRate: 'default',
        studySubjects: 'asc',
        createdAt: 'default',
      });
    } else if (
      column === 'studySubjects' &&
      columnsSorting.studySubjects === 'asc'
    ) {
      setColumnsSorting({
        capacity: 'default',
        maxCapacity: 'default',
        maxCapacityUpdatedAt: 'default',
        payRate: 'default',
        studySubjects: 'desc',
        createdAt: 'default',
      });
    } else if (
      column === 'createdAt' &&
      columnsSorting.createdAt === 'default'
    ) {
      setColumnsSorting({
        capacity: 'default',
        maxCapacity: 'default',
        maxCapacityUpdatedAt: 'default',
        payRate: 'default',
        studySubjects: 'default',
        createdAt: 'asc',
      });
    } else if (column === 'createdAt' && columnsSorting.createdAt === 'asc') {
      setColumnsSorting({
        capacity: 'default',
        maxCapacity: 'default',
        maxCapacityUpdatedAt: 'default',
        payRate: 'default',
        studySubjects: 'default',
        createdAt: 'desc',
      });
    } else {
      setColumnsSorting({
        capacity: 'default',
        maxCapacity: 'default',
        maxCapacityUpdatedAt: 'default',
        payRate: 'default',
        studySubjects: 'default',
        createdAt: 'default',
      });
    }
  };

  const handleSearch = (searchValue: string) => {
    setSearch(searchValue);
    setCurrentPage(1);
  };

  const handleAddStatus = (statusValue: string) => {
    setStatuses((prevState) => [...prevState, statusValue]);
    setCurrentPage(1);
  };

  const handleRemoveStatus = (statusValue: string) => {
    setStatuses((prevState) =>
      statuses.filter((status) => status !== statusValue)
    );
    setCurrentPage(1);
  };

  const handleClearStatuses = () => {
    setStatuses([]);
    setCurrentPage(1);
  };

  const handleCourse = (course: any) => {
    setCourse([course]);
    setCurrentPage(1);
  };

  const handleClearCourse = () => {
    setCourse([]);
    setCurrentPage(1);
  };

  const handleIsTestUser = (isTestUser: boolean) => {
    setIsTestUser(isTestUser);
    setCurrentPage(1);
  };

  const handleExtraFilter = (extraFilter: any) => {
    setExtraFilter(extraFilter);
    setCurrentPage(1);
  };

  const showEnrollmentPageModalHandler = (enrollmentId: string) => {
    setEnrollmentId(enrollmentId);
    setShowSessionModal(true);
    setModalPage('enrollment');
  };

  const showStudentPageModalHandler = (studentId: string) => {
    setStudentId(studentId);
    setShowSessionModal(true);
    setModalPage('student');
  };

  const showSessionPageModalHandler = (sessionId: string) => {
    setSessionId(sessionId);
    setShowSessionModal(true);
    setModalPage('session');
  };

  const handleCloseModal = () => {
    setStudentId(undefined);
    setShowSessionModal(false);
    setModalPage(undefined);
  };

  return (
    <div
      style={{
        backgroundColor: COLORS.SECTION_BACKGROUND,
        color: COLORS.TEXT_PRIMARY,
        minHeight: 'calc(100vh - 74px)',
      }}
    >
      {/*TABLE HEADER*/}
      {!isLoadingMentorStatistics && (
        <UsersTableInfo
          mentorStatistics={mentorStatistics}
          handleSearch={handleSearch}
          search={search}
          handleAddStatus={handleAddStatus}
          handleRemoveStatus={handleRemoveStatus}
          statuses={statuses}
          handleCourse={handleCourse}
          handleClearCourse={handleClearCourse}
          course={course}
          handleIsTestUser={handleIsTestUser}
          isTestUser={isTestUser}
          handleExtraFilter={handleExtraFilter}
          extraFilter={extraFilter}
          handleClearStatuses={handleClearStatuses}
        />
      )}

      {showSessionModal && (
        <SessionModal
          studentId={studentId}
          enrollmentId={enrollmentId}
          sessionId={sessionId}
          page={modalPage}
          isOpen={true}
          onClose={handleCloseModal}
        />
      )}

      {/*TABLE*/}
      <div className="overflow-auto">
        <table className="mentors-table">
          {/*TABLE COLUMNS*/}
          <MentorsTableHeader>
            <tr>
              <th style={{ textWrap: 'nowrap' }}>
                <span>Mentor Name</span>
              </th>
              <th>
                <span>Status</span>
              </th>

              <th className="" onClick={() => handleColumnSorting('capacity')}>
                <div>
                  <span>Capacity</span>
                  <TableColumnSortButtons sortType={columnsSorting.capacity} />
                </div>
              </th>
              <th
                className=""
                onClick={() => handleColumnSorting('maxCapacity')}
              >
                <div>
                  <span className="table-head-span">Max Cap.</span>
                  <TableColumnSortButtons
                    sortType={columnsSorting.maxCapacity}
                  />
                </div>
              </th>
              <th
                className=""
                onClick={() => handleColumnSorting('maxCapacityUpdatedAt')}
              >
                <div>
                  <span className="table-head-span">Cap. Last Update</span>
                  <TableColumnSortButtons
                    sortType={columnsSorting.maxCapacityUpdatedAt}
                  />
                </div>
              </th>
              <th className="" onClick={() => handleColumnSorting('payRate')}>
                <div>
                  <span className="table-head-span">Pay Rate</span>
                  <TableColumnSortButtons sortType={columnsSorting.payRate} />
                </div>
              </th>
              <th>
                <span>Universities</span>
              </th>
              <th>
                <span>Courses</span>
              </th>
              <th
                className=""
                onClick={() => handleColumnSorting('studySubjects')}
              >
                <div>
                  <span>Study Subjects</span>
                  <TableColumnSortButtons
                    sortType={columnsSorting.studySubjects}
                  />
                </div>
              </th>
              <th
                style={{ textWrap: 'nowrap' }}
                className=""
                onClick={() => handleColumnSorting('createdAt')}
              >
                <div>
                  <span className="table-head-span">Created At</span>
                  <TableColumnSortButtons sortType={columnsSorting.createdAt} />
                </div>
              </th>
              <th>
                <span>Access</span>
              </th>
            </tr>
          </MentorsTableHeader>

          {/*TABLE ROWS*/}
          <MentorPageSessionModal.Provider
            value={{
              showEnrollmentPageModalHandler,
              showSessionPageModalHandler,
              showStudentPageModalHandler,
            }}
          >
            <tbody>
              {!isLoadingMentors ? (
                mentors?.mentors?.map((item) => (
                  <MentorsTableRow
                    key={item?.mentor?._id}
                    mentorData={item}
                    refetchSearchMentors={refetchSearchMentors}
                    refetchMentorStatistics={refetchMentorStatistics}
                  />
                ))
              ) : (
                <AgoraSpinner fontSize={32} margin="32px" />
              )}
            </tbody>
          </MentorPageSessionModal.Provider>
        </table>
      </div>

      {/*PAGINATION*/}
      <div
        style={{
          width: '100%',
          padding: '24px 24px 120px 24px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <span
            style={{ color: COLORS.TEXT_SECONDARY, marginRight: '8px' }}
            className="mentors-table-pagination-label"
          >
            Rows per page
          </span>
          <select
            onChange={(e) => {
              const selectedValue = e.target.value;
              setPageSize(Number(selectedValue));
            }}
            className="mentors-row-custom-select-component pagination-select-container"
            style={{
              borderColor: COLORS.TEXT_PRIMARY,
              color: COLORS.TEXT_PRIMARY,
              backgroundColor: COLORS.SECTION_BACKGROUND,
            }}
            defaultValue={10}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={mentors?.total}>All</option>
          </select>
        </div>

        <div
          style={{ color: COLORS.TEXT_SECONDARY }}
          className="mentors-table-pagination-label"
        >
          {`${(currentPage - 1) * pageSize + 1}-${Math.min(
            currentPage * pageSize,
            mentors?.total || 0
          )} of ${mentors?.total || 0}`}
        </div>

        {/*PAGINATION NAVIGATOR*/}
        <div>
          <Pagination
            className="custom-pagination"
            showSizeChanger={false}
            current={currentPage}
            defaultCurrent={1}
            total={mentors?.total}
            pageSize={pageSize}
            onChange={(page) => setCurrentPage(page)}
            prevIcon={
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <LeftOutlined
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                  }}
                />
              </div>
            }
            nextIcon={
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <RightOutlined
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                  }}
                />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MentorsV3;
