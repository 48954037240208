import LoadingGif from '@assets/icons/video-icons/video-meeting-loading.gif';
const VideoMeetingLoading = () => {
  return (
    <section className="flex flex-col items-center justify-center w-full h-[calc(100vh-56px)] laptop:h-full">
      <img src={LoadingGif} alt="Loading" />
      <h1 className="text-xs leading-4">Starting call...</h1>
    </section>
  );
};

export default VideoMeetingLoading;
