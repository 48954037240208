import { MENTOR_ROLE } from '@shared/constants';
import { useAuthState, useGetUserProfile } from '@shared/react';
import StudentAside from './StudentAside/StudentAside';
import StudentMain from './StudentMain/StudentMain';

interface StudentProfileProps {
  userId?: string;
}

const StudentProfile = (props: StudentProfileProps) => {
  const { userId } = props;

  const user = useAuthState();
  const { data: userProfile } = useGetUserProfile(userId ?? user.userId);

  const isExternalViewer = user.userId !== userProfile?._id;
  const isMentorViewer = user.roles.includes(MENTOR_ROLE);

  return (
    <section className="flex flex-col p-4 gap-4 max-w-[1654px] m-auto tablet:flex-row tablet:items-start">
      <StudentAside
        hasEditPermissions={!isExternalViewer}
        userProfile={userProfile}
      />
      <StudentMain userProfile={userProfile} isMentorViewer={isMentorViewer} />
    </section>
  );
};

export default StudentProfile;
