import { UserProfileDTO } from '@shared/types/DTOs/UserDTO';
import OthersCoursesSection from './OthersCoursesSection';
import YourCoursesSection from './YourCoursesSection';

interface CoursesTabProps {
  userProfile: Partial<UserProfileDTO>;
}

const CoursesTab = (props: CoursesTabProps) => {
  const { userProfile } = props;

  return (
    <section className="flex flex-col gap-4">
      <YourCoursesSection userProfile={userProfile} />
      <OthersCoursesSection userProfile={userProfile} />
    </section>
  );
};

export default CoursesTab;
