import classNames, { ArgumentArray } from 'classnames';
import moment, { Moment } from 'moment';
import { extendTailwindMerge } from 'tailwind-merge';
import { z, ZodTypeAny } from 'zod';

const customTwMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [{ text: ['xxs', 'xsm'] }],
    },
  },
});

export const mergeClassNames = (...classes: ArgumentArray) =>
  customTwMerge(classNames(...classes));

export const getValidMoment = (date: string | null): Moment | null => {
  const parsedDate = moment(date);
  return parsedDate.isValid() ? parsedDate : null;
};

export const getStartDateValue = (value?: Moment) =>
  value?.startOf('day') || moment().subtract(1, 'month');

export const getEndDateValue = (value?: Moment) =>
  value?.endOf('day') || moment().add(1, 'month');

export const formatEnumValue = (value?: string) => value?.split('_').join(' ');

export const getHasValue = (value: string | number) => {
  return value !== undefined && value !== null && value !== '';
};

export const isValidWiseField = (value: string) =>
  /^[A-Za-z0-9-./?:(),+ ]+$/.test(value);

export const getNumericEnumValues = <T extends object>(enumObj: T): number[] =>
  Object.values(enumObj).filter(
    (value) => typeof value === 'number'
  ) as number[];

export const getTimestampFromMeetingTime = (
  timestamp: string,
  meetingStartDate: string
) => {
  const date = moment(meetingStartDate);
  const [hours, minutes, secondsWithMs] = timestamp.split(':');
  const [seconds, milliseconds] = secondsWithMs.split('.');

  // Add each part to the date
  return date
    .add(hours, 'hours')
    .add(minutes, 'minutes')
    .add(seconds, 'seconds')
    .add(milliseconds, 'milliseconds')
    .format('HH:mm');
};
