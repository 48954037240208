import { ToastProps } from '@components/V4/Toast';
import { MediaDevice } from '@zoom/videosdk';

export const mountDevices = async (stream?: MediaStream) => {
  let devices;
  let localStream = stream;
  const microphones: any = [];
  const cameras: any = [];

  try {
    if (!localStream) {
      localStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
          aspectRatio: 16 / 9,
          width: {
            min: 640,
            ideal: 1280,
            max: 1280,
          },
          height: {
            min: 360,
            ideal: 720,
            max: 720,
          },
          frameRate: {
            min: 15,
            ideal: 25,
            max: 30,
          },
        },
      });
    }

    devices = await navigator.mediaDevices.enumerateDevices();

    devices.forEach((device) => {
      if (device.kind === 'audioinput') {
        if (device.deviceId !== 'communications') {
          microphones.push({
            deviceId: device.deviceId,
            label: device.label,
          });
        }
      } else if (device.kind === 'videoinput') {
        cameras.push({
          deviceId: device.deviceId,
          label: device.label,
        });
      }
    });
  } catch (error) {
    console.log({ error });
  }

  return {
    microphones,
    cameras,
    stream: localStream,
  };
};

export const findRemovedDevicesById = (
  oldDevices: MediaDevice[],
  newDevices: MediaDevice[]
) => {
  const newDeviceIds = new Set(newDevices.map((device) => device.deviceId));
  const missingDevice = oldDevices.find(
    (device) => !newDeviceIds.has(device.deviceId)
  );
  return missingDevice ? missingDevice.deviceId : null;
};

export const isCameraHDCapable = async (deviceId: string): Promise<boolean> => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();

    const device = devices.find(
      (d) => d.deviceId === deviceId && d.kind === 'videoinput'
    );

    if (!device) {
      throw new Error('No camera found with the provided deviceId');
    }

    const stream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId: { exact: device.deviceId } },
    });

    const videoTrack = stream.getVideoTracks()[0];
    const capabilities = videoTrack.getCapabilities();

    videoTrack.stop();

    const widthMax =
      typeof capabilities.width === 'number'
        ? capabilities.width
        : capabilities.width?.max;
    const heightMax =
      typeof capabilities.height === 'number'
        ? capabilities.height
        : capabilities.height?.max;

    if (!widthMax || !heightMax) {
      return false;
    }

    const isHDCapable = widthMax >= 1280 && heightMax >= 720;

    return isHDCapable;
  } catch (error) {
    console.error('Error checking if camera is HD capable:', error);
    return false;
  }
};

export const hasScreenShareCapability = () =>
  !!(
    navigator.mediaDevices &&
    typeof navigator.mediaDevices.getDisplayMedia === 'function'
  );

export const getStartVideoToastErrors = (error: any): ToastProps => {
  switch (error.type) {
    case 'CAN_NOT_DETECT_CAMERA':
      return {
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Cannot detect camera device.',
      };
    case 'CAN_NOT_FIND_CAMERA':
      return {
        variant: 'error',
        messageTitle: 'Error',
        messageBody:
          'The provided camera device ID is not included in the camera device list.',
      };
    case 'VIDEO_USER_FORBIDDEN_CAPTURE':
      return {
        variant: 'error',
        messageTitle: 'Error',
        messageBody:
          'You have forbidden the use of the camera or the camera is taken by other programs.',
      };
    case 'VIDEO_ESTABLISH_STREAM_ERROR':
      return {
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Video WebSocket is broken.',
      };
    case 'VIDEO_CAMERA_IS_TAKEN':
      return {
        variant: 'error',
        messageTitle: 'Error',
        messageBody:
          "Please check if another app is using your camera, then close it, followed by a refresh, if you'd like to be seen.",
      };
    default:
      return {
        variant: 'error',
        messageTitle: 'Error',
        messageBody: error.reason,
      };
  }
};

export const stopTracks = (
  stream?: MediaStream,
  filter?: 'audio' | 'video'
) => {
  const tracks = stream
    ?.getTracks()
    .filter((track) => (filter ? track.kind === filter : true));

  if (!tracks || !tracks.length) return;

  tracks.forEach((track) => track.stop());
};

export const isSharedArrayBufferEnabled = () =>
  typeof SharedArrayBuffer !== 'undefined';
