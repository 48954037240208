export const LANDING = '/';
export const WELCOME = '/auth/welcome';
export const ONBOARDING = '/onboarding';
export const UPGRADED_ACCOUNT = '/upgraded';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/reset-password';
export const LEARNING_PLAN = '/plan';
export const MEETING_SPEEDTEST = '/meeting/speed-test';
export const MEETING_FEEDBACK = '/meeting/feedback';
export const MEETING_MENTOR_AFTER = '/meeting/mentor-after';
export const COURSE_LIST = '/course/list';
export const NEW_ENROLLMENTS = '/enrollments';
export const COURSES = '/courses';
export const MY_STUDENTS = '/my-students';
export const KNOWLEDGE_BASE_ASSISTANT = '/knowledge-base-assistant';
export const SESSION_HISTORY = '/session-history';
export const COURSE_CATALOG = '/catalog';
export const COURSE_PAGE = '/course';
export const NOTIFICATIONS = '/notifications';

export const KNOWLEDGE = '/knowledge';
export const VIDEO_MEETING = '/video-meeting';
export const STUDENT_PROFILE = '/student-profile';

export const NEW_PAGE = '/admin/page/new';
export const MANAGE_USERS = '/admin/manage-users';
export const MANAGE_USERS_V3 = '/admin/manage-users-v3';
export const MANAGE_MENTORS = '/admin/mentors';
export const MANAGE_MENTORS_V3 = '/admin/mentors-v3';
export const MANAGE_STUDENTS = '/admin/students';
export const MANAGE_RELIABILITY_MENTORS = '/admin/reliability-mentors';
export const MENTOR_REQUESTS_OVERVIEW = '/admin/mentor-requests';
export const MANAGE_COURSES = '/admin/manage-courses';
export const ANALYTICS = '/admin/analytics';
export const MEETING_MANAGEMENT = '/admin/meeting-management';
export const CHECKLIST_MANAGEMENT = '/admin/checklist-management';
export const CHECKLIST_NEW = '/admin/checklists/new';
export const NOTIFY_USERS = '/admin/notify-users';
export const OLD_ENROLLMENTS = '/admin/old-enrollments';
export const CUSTOMER_SUCCESS_DASHBOARD = '/admin/customer-success';
export const NPS_DASHBOARD = '/admin/nps-dashboard';
export const ACTIVITY_REPORT = '/admin/activity-report';
export const EXTRA_SERVICES = '/admin/extra-services';
export const FLAGS = '/admin/flags';
export const MANAGE_KNOWLEDGE = '/admin/manage-knowledge-base';
export const KNOWLEDGE_BASE = '/admin/knowledge-base';

export const SALES_NEW_USER = '/sales/new-user';
export const SALES_RESET_USER_ACTIVATION_LINK =
  '/sales/reset-user-activation-link';
export const SALES_NEW_MEETING = '/sales/new-meeting';
export const SALES_UPCOMING_CALLS = '/sales/upcoming-calls';
export const SALES_TOOLS_POPUP = '/sales/onboarding';
export const SALES_PLAN_PDF = '/sales/plan-pdf';

export const MENTOR_HOME = '/mentor/home';
export const MENTOR_STUDENTS = '/mentor/students';
export const MENTOR_MEETINGS = '/mentor/meetings';
// export const MENTOR_KNOWLEDGE_BASE = '/mentor/knowledge-base';
export const MENTOR_SETTINGS = '/mentor/settings';
export const MENTOR_PAYMENTS = '/mentor/payments';
export const MENTOR_CHECKLISTS = '/mentor/checklists';
export const MENTOR_REQUESTS_INBOX = '/mentor/requests';

export const PRIVACY_POLICY = '/privacy-policy';

// DEMO CTA Pages
export const DEMO_LEARNING_PLAN = '/plan-demo';
