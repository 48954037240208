import styles from '@styles/components/mentor-feedback.module.scss';
import { Rate } from 'antd';
import { COLORS } from '../../../../../v3/global';
import NextButton from './NextButton';
import StepTitle from './StepTitle';

type Props = {
  title: string;
  categoryCardsOptions: Array<{ name: string; iconContent: JSX.Element }>;
  activeValue: { index: number; name: string };
  handleSelect: any;
  categoryActiveOptions?: any[];
  setCategoryActiveOptions?: any;
  showNext?: boolean;
  increaseStep: any;
  decreaseStep?: any;
  ratingValueStep?: any;
  handleRatingValueStep: (value: any) => void;
  ratingExplanation?: any;
  handleRatingExplanation?: (value: any) => void;
  inputItem?: any;
};

const SelectStep = ({
  title,
  categoryCardsOptions,
  activeValue,
  handleSelect,
  categoryActiveOptions,
  setCategoryActiveOptions,
  showNext,
  increaseStep,
  decreaseStep,
  ratingValueStep,
  handleRatingValueStep,
  ratingExplanation,
  handleRatingExplanation,
  inputItem,
}: Props) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <div>
        <StepTitle title={title} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          className={styles.cards}
        >
          {/*{categoryCardsOptions.map((card, index) => (*/}
          {/*  <SelectCard*/}
          {/*    activeValue={activeValue}*/}
          {/*    handleClick={() => handleSelect({ index, name: card.name })}*/}
          {/*    iconContent={card.iconContent}*/}
          {/*    name={card.name}*/}
          {/*    key={`${card.name}-${index}`}*/}
          {/*  />*/}
          {/*))}*/}
          <Rate
            value={ratingValueStep}
            style={{ fontSize: '100px', color: '#33A9AB' }}
            onChange={(value) => {
              handleRatingValueStep(value);
              handleSelect({ index: value - 1, name: value.toString() });
            }}
          />
          {inputItem}
        </div>

        {/*{categoryActiveOptions ? (*/}
        {/*  <TextOptions*/}
        {/*    optionsArray={categoryActiveOptions}*/}
        {/*    setOptionsArray={setCategoryActiveOptions}*/}
        {/*    toggleSelected={toggleSelectedCategory}*/}
        {/*  />*/}
        {/*) : (*/}
        {/*  <div style={{ height: '150px' }}></div>*/}
        {/*)}*/}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          height: '69px',
          width: '100%',
        }}
      >
        {decreaseStep && (
          <button
            style={{
              marginLeft: '6px',
              marginRight: '6px',
              color: COLORS.TEXT_SECONDARY,
            }}
            className={`${styles.feedbackLink} main-green-link`}
            onClick={decreaseStep}
          >
            Back
          </button>
        )}

        {ratingValueStep !== 0 &&
        ratingExplanation &&
        ratingExplanation.replace(/\s/g, '').length >=
          inputItem?.props?.minCharactersRequired ? (
          <NextButton handleClick={increaseStep} />
        ) : // <div style={{ height: '69px' }}></div>
        null}
      </div>
    </div>
  );
};

export default SelectStep;
