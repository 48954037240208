import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Button from '@components/V4/Button';
import Input from '@components/V4/Inputs/Input';
import Modal from '@components/V4/Modal/Modal';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import authHeader from '@services/auth-header';
import { extraServicesApis } from '@shared/apis';
import {
  CloseModalProps,
  Enrollment,
  ExtraService,
  ServiceType,
  Session,
} from '@shared/common';
import { BASE_URL } from '@shared/frontendEnv';
import { useGetAllMentors } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import axios from 'axios';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';

type UserArg = { _id: string; fullName: string };

type Props = CloseModalProps & {
  name?: string;
  users?: UserArg[];
  enrollmentId?: string;
  date?: Moment;
  isOnHomePage?: boolean;
  defaultEnrollment?: Enrollment;
  defaultStudent?: {
    fullName?: string;
    id: string;
  };
  defaultData?: ExtraService;
  defaultSession?: Session;
  postSuccess?: () => void;
  handleRowTouched: () => void;
};

const formDataSchema = z.object({
  serviceType: z
    .string()
    .min(1, { message: 'Please select a valid service type.' }),
  description: z
    .string()
    .min(1, { message: 'Please enter a valid description.' }),
  amount: z.number().min(0, { message: 'Please enter an amount.' }),
  currency: z.string().min(1, { message: 'Please select a valid currency.' }),
  mentor: z.string().min(1, { message: 'Please select a mentor.' }),
  yearMonth: z.string().min(1, { message: 'Please select a valid date.' }),
});

type FormData = z.infer<typeof formDataSchema>;

const ExtraServicesModal = ({
  visible,
  hide,
  handleRowTouched,
  defaultData,
}: Props) => {
  const [showModal, setShowModal] = useState(true);
  const [isCreating, setIsCreating] = useState(false);

  const [showToast] = useToast();
  const query = useQueryClient();

  const { data: mentors } = useGetAllMentors({
    active: true,
  });

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      serviceType: defaultData?.serviceType,
      description: defaultData?.description,
      amount: defaultData?.amount,
      currency: defaultData?.currency,
      mentor: defaultData?.mentor._id,
      yearMonth:
        defaultData?.year && defaultData?.month
          ? moment([defaultData.year, defaultData.month - 1]).toString()
          : '',
    },
  });

  const { field: serviceTypeField, fieldState: serviceTypeFieldState } =
    useController({
      name: 'serviceType',
      control,
    });

  const { field: currencyField, fieldState: currencyFieldState } =
    useController({
      name: 'currency',
      control,
    });

  const { field: mentorField, fieldState: mentorFieldState } = useController({
    name: 'mentor',
    control,
  });
  const { field: yearMonthField, fieldState: yearMonthFieldState } =
    useController({
      name: 'yearMonth',
      control,
    });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const month = moment(data.yearMonth).month() + 1;
      const year = moment(data.yearMonth).year();
      setIsCreating(true);

      const endpointURL = `${BASE_URL}/api/v1/extra-services/admin${
        defaultData?._id ? '/' + defaultData?._id : ''
      }`;

      const dataToSend = {
        serviceType: data.serviceType,
        description: data.description,
        amount: data.amount,
        currency: data.currency,
        mentorId: data.mentor,
        month: month,
        year: year,
      };

      try {
        if (defaultData?._id) {
          await axios.put(endpointURL, dataToSend, { headers: authHeader() });
        } else {
          await axios.post(endpointURL, dataToSend, { headers: authHeader() });
        }
        showToast({
          variant: 'success',
          messageTitle: 'Success',
          messageBody: `Extra service ${
            defaultData?._id ? 'edited' : 'created'
          } successfully.`,
        });
        await query.invalidateQueries(
          extraServicesApis.searchExtraServices.endpoint
        );
        hide();
      } catch (error) {
        console.error(error);
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: `Error ${
            defaultData?._id ? 'modifying' : 'creating'
          } extra service.`,
        });
      }
      setIsCreating(false);
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string | number>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (defaultData) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  const serviceTypeOptions = [
    { label: 'Webinar Host', value: ServiceType.WEBINAR_HOST },
    { label: 'Speaking Live Event', value: ServiceType.SPEAKING_LIVE_EVENT },
    { label: 'Referral', value: ServiceType.REFERRAL },
    { label: 'Marketing Action', value: ServiceType.MARKETING_ACTION },
    {
      label: 'Creative Writing Course',
      value: ServiceType.CREATIVE_WRITING_COURSE,
    },
    { label: 'Subscription', value: ServiceType.SUBSCRIPTION },
    { label: 'Extra Sessions', value: ServiceType.EXTRA_SESSIONS },
    { label: 'Resource Development', value: ServiceType.RESOURCE_DEVELOPMENT },
    {
      label: 'Extra Payment Per Session',
      value: ServiceType.EXTRA_PAYMENT_PER_SESSION,
    },
    { label: 'Mentor Training', value: ServiceType.MENTOR_TRAINING },
    { label: 'Other', value: ServiceType.OTHER },
  ];

  const currenciesOptions = ['EUR', 'RON', 'USD', 'GBP'];

  const mentorOptions = [
    { label: 'No Mentor', value: 'no-mentor' },
    ...(mentors?.map((mentor) => ({
      label: mentor?.fullName,
      value: mentor?._id,
    })) || []),
  ];

  return (
    <Modal isOpen={visible && showModal} onClose={hide} className="gap-4">
      <Modal.Header
        title={defaultData ? 'Edit extra service' : 'Create an extra service'}
      />

      <Modal.Body className="gap-1">
        <Select
          autoFocus
          size="large"
          label="Service Type"
          onSelect={(val) => serviceTypeField.onChange(val as ServiceType)}
          onBlur={serviceTypeField.onBlur}
          value={serviceTypeField.value}
          isValid={!serviceTypeFieldState.error}
          isTouched={serviceTypeFieldState.isTouched}
          errorText={serviceTypeFieldState.error?.message}
          allowClear={false}
          placeholder="Service Type"
          options={serviceTypeOptions}
          isRequired
        />

        <Input
          type="text"
          id="description"
          label="Description"
          placeholder="Description"
          isRequired
          isValid={!errors.description?.message}
          isTouched={touchedFields.description}
          errorText={errors.description?.message}
          {...register('description')}
        />

        <Input
          placeholder="Amount"
          id="amount"
          type="number"
          label="Amount"
          isRequired
          isValid={!errors.amount?.message}
          isTouched={touchedFields.amount}
          errorText={errors.amount?.message}
          {...register('amount', { valueAsNumber: true })}
        />

        <Select
          showSearch
          size="large"
          label="Currency"
          isRequired
          onSelect={(val) => currencyField.onChange(val)}
          onBlur={currencyField.onBlur}
          value={currencyField.value}
          placeholder="Currency"
          isValid={!currencyFieldState.error}
          isTouched={currencyFieldState.isTouched}
          errorText={currencyFieldState.error?.message}
          allowClear={false}
          options={currenciesOptions.map((currency) => ({
            label: currency,
            value: currency,
          }))}
        />

        <Select
          size="large"
          showSearch
          label="Mentor"
          placeholder="Select a mentor"
          isRequired
          onSelect={(val) => mentorField.onChange(val)}
          onBlur={mentorField.onBlur}
          value={mentorField.value}
          isValid={!mentorFieldState.error}
          isTouched={mentorFieldState.isTouched}
          errorText={mentorFieldState.error?.message}
          allowClear={false}
          options={mentorOptions}
        />

        <div className="">
          <AgoraDatePicker
            label="Year & Month"
            picker="month"
            isRequired
            value={
              yearMonthField.value ? moment(yearMonthField.value) : undefined
            }
            onChange={(value) =>
              yearMonthField.onChange(moment(value).format('YYYY-MM-DD'))
            }
            placeholder="Select Month"
            onBlur={yearMonthField.onBlur}
            isValid={!yearMonthFieldState.error}
            isTouched={yearMonthFieldState.isTouched}
            errorText={yearMonthFieldState.error?.message}
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="primary"
          onMouseDown={submitHandler}
          disabled={isCreating}
          isLoading={isCreating}
          buttonText="Create Extra Service"
          className="ml-auto"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ExtraServicesModal;
