import { LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import EnrolmentEnrolledInModalRow from '@components/V3/EnrollmentsModalMentors/EnrolmentEnrolledInModalRow';
import EnrolmentTeachingModalRow from '@components/V3/EnrollmentsModalMentors/EnrolmentTeachingModalRow';
import AgoraSpinner from '@components/V3/Utils/AgoraSpinner';
import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { Pagination } from 'antd';
import React from 'react';
import { COLORS } from '../../../v3/global';
import './enrollments-modal-mentors.styles.scss';

type Props = {
  handleCurrentPage: (page: string) => void;
  enrollments: any;
  handleTeachingPage: (page: number) => void;
  handleBeingTaughtPage: (page: number) => void;
  handleIsEnded: (isEnded: boolean) => void;
  refetchEnrollments: () => any;
  handleCurrentEnrolment: (id: string) => any;
  isLoadingEnrollments: boolean;
  selectedTab: string;
  handleSelectedTab: (tab: string) => void;
  handleNewEnrolmentTeaching: (value: boolean) => void;
  handleLastMeeting: (lastMeeting: string) => void;
  handleEnrolmentData?: (enrolmentData: any) => void;
};

const EnrollmentsPage = ({
  handleCurrentPage,
  enrollments,
  handleTeachingPage,
  handleBeingTaughtPage,
  handleIsEnded,
  refetchEnrollments,
  handleCurrentEnrolment,
  isLoadingEnrollments,
  selectedTab,
  handleSelectedTab,
  handleNewEnrolmentTeaching,
  handleLastMeeting,
  handleEnrolmentData,
}: Props) => {
  return (
    <div>
      {/*TABS SECTION ONE*/}
      <div style={{ marginBottom: '16px' }}>
        <nav
          style={{ overflowX: 'auto' }}
          className="flex space-x-4"
          aria-label="Tabs"
        >
          <span
            onClick={() => {
              handleSelectedTab('Active');
              handleIsEnded(false);
              handleTeachingPage(1);
              handleBeingTaughtPage(1);
              refetchEnrollments();
            }}
            style={{
              cursor: 'pointer',
              color:
                selectedTab === 'Active' ? COLORS.BLACK : COLORS.TEXT_PRIMARY,
              background:
                selectedTab === 'Active'
                  ? COLORS.PRIMARY
                  : COLORS.DARK_GREY_ALT,
              borderRadius: '32px',
              paddingLeft: '24px',
              paddingRight: '24px',
              paddingTop: '4px',
              paddingBottom: '4px',
              height: '24px',
              fontSize: '11px',
              lineHeight: '15px',
            }}
            aria-current={selectedTab === 'Active' ? 'page' : undefined}
          >
            Active
          </span>

          <span
            onClick={() => {
              handleSelectedTab('Ended');
              handleIsEnded(true);
              handleTeachingPage(1);
              handleBeingTaughtPage(1);
              refetchEnrollments();
            }}
            style={{
              cursor: 'pointer',
              color:
                selectedTab === 'Ended' ? COLORS.BLACK : COLORS.TEXT_PRIMARY,
              background:
                selectedTab === 'Ended' ? COLORS.PRIMARY : COLORS.DARK_GREY_ALT,
              borderRadius: '32px',
              paddingLeft: '24px',
              paddingRight: '24px',
              paddingTop: '4px',
              paddingBottom: '4px',
              height: '24px',
              fontSize: '11px',
              lineHeight: '15px',
            }}
            aria-current={selectedTab === 'Ended' ? 'page' : undefined}
          >
            Ended
          </span>
        </nav>
      </div>

      {isLoadingEnrollments ? (
        <AgoraSpinner fontSize={32} margin="32px" />
      ) : (
        <>
          <div style={{ width: '100%', marginTop: '8px' }}>
            {/*SECTION ONE*/}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '16px',
              }}
            >
              <p color={COLORS.TEXT_PRIMARY} className="modal-section-title">
                Teaching
              </p>
              <SecondaryButton
                onClick={() => {
                  handleCurrentPage('new-enrollment');
                  handleNewEnrolmentTeaching(true);
                }}
                backgroundColor={COLORS.WHITE}
                height="27px"
                padding="4px 10px 4px 10px"
                margin="0px"
              >
                <PlusOutlined
                  style={{ color: COLORS.BLACK, fontSize: '13px' }}
                />{' '}
                <span
                  className="button-text"
                  style={{ marginLeft: '8px', color: COLORS.BLACK }}
                >
                  Add Enrolment
                </span>
              </SecondaryButton>
            </div>

            {/*SECTION ONE COLUMNS*/}
            <div
              style={{
                display: 'flex',
                marginBottom: '12px',
                width: '100%',
              }}
            >
              <span
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  width: '30%',
                  marginRight: '8px',
                }}
                className="modal-column-text"
              >
                Student
              </span>
              <span
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  width: '10%',
                  marginRight: '8px',
                }}
                className="modal-column-text"
              >
                Meetings
              </span>
              <span
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  width: '15%',
                  marginRight: '8px',
                }}
                className="modal-column-text"
              >
                Course
              </span>
              <span
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  width: '10%',
                  marginRight: '8px',
                }}
                className="modal-column-text"
              >
                Grad. Year
              </span>
              <span
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  width: '15%',
                  marginRight: '8px',
                }}
                className="modal-column-text"
              >
                Last Session
              </span>
              <span
                style={{ color: COLORS.TEXT_SECONDARY, width: '20%' }}
                className="modal-column-text"
              >
                Actions
              </span>
            </div>
          </div>

          {/*TABLE HEADER DIVIDER*/}
          <div style={{ borderTopWidth: '0.5px' }} className="relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
          </div>

          {enrollments?.teachingEnrollmentsDetails?.enrollments?.length ===
          0 ? (
            <div style={{ marginTop: '14px' }}>
              <p
                style={{ color: COLORS.TEXT_SECONDARY, textAlign: 'center' }}
                className="no-results-text"
              >
                No results
              </p>
            </div>
          ) : (
            enrollments?.teachingEnrollmentsDetails?.enrollments?.map(
              (enrolment: any) => (
                <EnrolmentTeachingModalRow
                  enrolment={enrolment}
                  refetchEnrollments={refetchEnrollments}
                  handleCurrentPage={handleCurrentPage}
                  handleCurrentEnrolment={handleCurrentEnrolment}
                  handleLastMeeting={handleLastMeeting}
                  handleEnrolmentData={handleEnrolmentData}
                />
              )
            )
          )}

          {/*SECTION ONE PAGINATION*/}
          {/*PAGINATION NAVIGATOR*/}
          {enrollments?.teachingEnrollmentsDetails?.enrollments?.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'fle-end',
                  alignItems: 'center',
                  marginTop: '12px',
                  width: 'fit-content',
                }}
              >
                <Pagination
                  className="custom-pagination"
                  showSizeChanger={false}
                  defaultCurrent={1}
                  current={enrollments?.teachingEnrollmentsDetails?.page}
                  total={enrollments?.teachingEnrollmentsDetails?.total}
                  pageSize={enrollments?.teachingEnrollmentsDetails?.limit}
                  onChange={(page) => handleTeachingPage(page)}
                  prevIcon={
                    <div
                      style={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <LeftOutlined
                        style={{
                          color: COLORS.TEXT_SECONDARY,
                        }}
                      />
                    </div>
                  }
                  nextIcon={
                    <div
                      style={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <RightOutlined
                        style={{
                          color: COLORS.TEXT_SECONDARY,
                        }}
                      />
                    </div>
                  }
                />
              </div>
            </div>
          )}

          {/*SECTION TWO*/}
          <div
            style={{
              width: '100%',
              marginTop: '32px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '16px',
              }}
            >
              <p color={COLORS.TEXT_PRIMARY} className="modal-section-title">
                Enrolled in
              </p>
              <SecondaryButton
                onClick={() => {
                  handleCurrentPage('new-enrollment');
                  handleNewEnrolmentTeaching(false);
                }}
                backgroundColor={COLORS.WHITE}
                height="27px"
                padding="4px 10px 4px 10px"
                margin="0px"
              >
                <PlusOutlined
                  style={{ color: COLORS.BLACK, fontSize: '13px' }}
                />{' '}
                <span
                  className="button-text"
                  style={{ marginLeft: '8px', color: COLORS.BLACK }}
                >
                  Add Enrolment
                </span>
              </SecondaryButton>
            </div>
          </div>

          {/*SECTION TWO COLUMNS*/}
          <div
            style={{
              display: 'flex',
              marginBottom: '12px',
              width: '100%',
            }}
          >
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
                width: '50%',
                marginRight: '8px',
              }}
              className="modal-column-text"
            >
              Course
            </span>
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
                width: '30%',
                marginRight: '8px',
              }}
              className="modal-column-text"
            >
              Status
            </span>
            <span
              style={{ color: COLORS.TEXT_SECONDARY, width: '20%' }}
              className="modal-column-text"
            >
              Actions
            </span>
          </div>

          {/*TABLE HEADER DIVIDER*/}
          <div style={{ borderTopWidth: '0.5px' }} className="relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
          </div>

          {/*SECTION TWO ENROLLMENTS ROWS*/}
          {enrollments?.beingTaughtEnrollmentsDetails?.enrollments?.length ===
          0 ? (
            <div style={{ marginTop: '14px' }}>
              <p
                style={{ color: COLORS.TEXT_SECONDARY, textAlign: 'center' }}
                className="no-results-text"
              >
                No results
              </p>
            </div>
          ) : (
            enrollments?.beingTaughtEnrollmentsDetails?.enrollments?.map(
              (enrolment: any) => (
                <EnrolmentEnrolledInModalRow
                  enrolment={enrolment}
                  handleCurrentEnrolment={handleCurrentEnrolment}
                  refetchEnrollments={refetchEnrollments}
                  handleCurrentPage={handleCurrentPage}
                />
              )
            )
          )}

          {/*SECTION TWO PAGINATION*/}
          {/*PAGINATION NAVIGATOR*/}

          {enrollments?.beingTaughtEnrollmentsDetails?.enrollments?.length >
            0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'fle-end',
                  alignItems: 'center',
                  marginTop: '12px',
                  width: 'fit-content',
                }}
              >
                <Pagination
                  className="custom-pagination"
                  showSizeChanger={false}
                  defaultCurrent={1}
                  current={enrollments?.beingTaughtEnrollmentsDetails?.page}
                  total={enrollments?.beingTaughtEnrollmentsDetails?.total}
                  pageSize={enrollments?.beingTaughtEnrollmentsDetails?.limit}
                  onChange={(page) => handleBeingTaughtPage(page)}
                  prevIcon={
                    <div
                      style={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <LeftOutlined
                        style={{
                          color: COLORS.TEXT_SECONDARY,
                        }}
                      />
                    </div>
                  }
                  nextIcon={
                    <div
                      style={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <RightOutlined
                        style={{
                          color: COLORS.TEXT_SECONDARY,
                        }}
                      />
                    </div>
                  }
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EnrollmentsPage;
