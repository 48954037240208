import TableHeaderInfoCard from '@components/V3/TableHeaderInfoCard';
import { MENTOR_ROLE, STUDENT_STATUS } from '@shared/constants';
import { useGetAllCourses, useSearchUsersByFullName } from '@shared/react';
import AgoraSearchBar from '@utils/AgoraSearchbar';
import { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './students-table-header.styles.scss';
import Select from '@components/V4/Select/Select';
import AgoraDatePicker from '../Utils/InputsV3/AgoraDatePicker';

type Props = {
  studentStatistics?: any;
  search?: string;
  handleSearch: (searchValue: string) => void;
  handleFilterBy: (status: string | undefined) => void;
  handleEnrolledIn: (course: string | undefined) => void;
  handleMentoredBy: (mentor: string | undefined) => void;
  handleGraduationYear: (gradYear: number | undefined) => void;
  handleExtraFilter: (extraFilter: any) => void;
  extraFilter: any;
  handleClearStatuses?: () => void;
};

const StudentsTableHeader = ({
  studentStatistics,
  search,
  handleSearch,
  handleFilterBy,
  handleEnrolledIn,
  handleMentoredBy,
  handleGraduationYear,
  handleExtraFilter,
  extraFilter,
  handleClearStatuses,
}: Props) => {
  const { data: allCourses } = useGetAllCourses();

  const [mentorFullName, setMentorFullName] = useState('');

  const { data: allMentors } = useSearchUsersByFullName({
    fullName: mentorFullName,
    role: MENTOR_ROLE,
  });

  return (
    <div
      style={{
        backgroundColor: COLORS.DARK_GREY_ALT,
        padding: '24px 32px 32px 32px',
      }}
    >
      <div
        style={{
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/*HEADER TITLE AND BUTTON*/}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '24px',
          }}
        >
          <h1 style={{ color: COLORS.TEXT_PRIMARY }} className="header-title">
            Students
          </h1>
        </div>

        <div style={{ maxWidth: '1920px' }}>
          {/*TABLE HEADER INFO*/}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              marginBottom: '16px',
            }}
          >
            <TableHeaderInfoCard
              title={studentStatistics?.noCoursesAssigned ?? 'N/A'}
              description={'No courses assigned'}
              // status={'Active'}
              handleExtraFilter={handleExtraFilter}
              extraFilter={extraFilter}
            />
            <TableHeaderInfoCard
              title={studentStatistics?.onGoingCourse ?? 'N/A'}
              description={'Ongoing course'}
              handleExtraFilter={handleExtraFilter}
              extraFilter={extraFilter}
            />
            <TableHeaderInfoCard
              title={studentStatistics?.courseCompleted ?? 'N/A'}
              description={'Courses completed'}
              handleExtraFilter={handleExtraFilter}
              extraFilter={extraFilter}
            />
            <TableHeaderInfoCard
              title={studentStatistics?.noMentorAssigned ?? 'N/A'}
              description={'No mentor assigned'}
              // status={'At Capacity'}
              handleExtraFilter={handleExtraFilter}
              extraFilter={extraFilter}
            />
            <TableHeaderInfoCard
              title={studentStatistics?.needsMentorNow ?? 'N/A'}
              description={'Needs mentor now'}
              // status={'In Training'}
              handleExtraFilter={handleExtraFilter}
              extraFilter={extraFilter}
            />
            <TableHeaderInfoCard
              title={studentStatistics?.mentorAllocated ?? 'N/A'}
              description={'Mentor allocated'}
              handleExtraFilter={handleExtraFilter}
              extraFilter={extraFilter}
            />
            <TableHeaderInfoCard
              title={studentStatistics?.firstSessionScheduled ?? 'N/A'}
              description={'First session scheduled'}
              handleExtraFilter={handleExtraFilter}
              extraFilter={extraFilter}
            />
            <TableHeaderInfoCard
              title={studentStatistics?.firstSessionCompleted ?? 'N/A'}
              description={'First session completed'}
              handleExtraFilter={handleExtraFilter}
              extraFilter={extraFilter}
            />
          </div>

          {/*SEARCHBAR*/}
          <AgoraSearchBar
            search={search}
            handleSearch={handleSearch}
            placeholder={'Search by name, email, phone number...'}
          />

          {/*FILTERS*/}
          <div className="flex flex-wrap gap-4">
            {/*FILTER BY*/}
            <Select
              variant="secondary"
              className="max-w-64"
              label="Filter By"
              onSelect={(value) => handleFilterBy(value as string)}
              options={STUDENT_STATUS.map((value, index) => ({
                value,
                label: value,
              }))}
              onClear={() => handleFilterBy(undefined)}
            />

            {/*ENROLLED IN*/}
            <Select
              variant="secondary"
              className="max-w-64"
              label="Enrolled In"
              onSelect={(value) => handleEnrolledIn(value as string)}
              options={allCourses?.map((value) => ({
                value: value._id,
                label: value.title,
              }))}
              onClear={() => handleEnrolledIn(undefined)}
            />

            {/*MENTORED BY*/}
            <Select
              variant="secondary"
              className="max-w-64"
              label={'Mentored By'}
              onSelect={(value) => handleMentoredBy(value as string)}
              onSearch={(value) => setMentorFullName(value)}
              options={allMentors?.map((value) => ({
                value: value._id,
                label: value.fullName,
              }))}
              onClear={() => handleMentoredBy(undefined)}
            />

            <div className="flex flex-col w-64 max-w-64">
              <AgoraDatePicker
                size="default"
                label="Graduation Year"
                variant="secondary"
                allowClear
                picker="year"
                onChange={(_, dateString) => {
                  if (dateString) {
                    handleGraduationYear(parseInt(dateString));
                  } else {
                    handleGraduationYear(undefined);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentsTableHeader;
