import { UserProfileDTO } from '@shared/types/DTOs/UserDTO';
import ResumeEducationSection from './ResumeEducationSection/ResumeEducationSection';
import ResumeSchoolSection from './ResumeSchoolSection/ResumeSchoolSection';
import ResumeWorkSection from './ResumeWorkSection/ResumeWorkSection';

interface ResumeTabProps {
  userId: string;
  userProfile: Partial<UserProfileDTO>;
  isMentorViewer: boolean;
}

const ResumeTab = (props: ResumeTabProps) => {
  const { userId, userProfile, isMentorViewer } = props;

  const universitiesList = userProfile?.educationalBackground?.filter(
    (edu) => edu.educationalInstitution?.type === 'University'
  );

  const schoolsList = userProfile?.educationalBackground?.filter(
    (edu) => edu.educationalInstitution?.type === 'School'
  );

  return (
    <section className="flex flex-col gap-4">
      <ResumeWorkSection
        isMentorViewer={isMentorViewer}
        userId={userId}
        experiences={userProfile.experiences ?? []}
      />

      <ResumeEducationSection
        isMentorViewer={isMentorViewer}
        userId={userId}
        eduList={universitiesList ?? []}
      />

      <ResumeSchoolSection
        isMentorViewer={isMentorViewer}
        userId={userId}
        eduList={schoolsList ?? []}
      />
    </section>
  );
};

export default ResumeTab;
