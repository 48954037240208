import { useState } from 'react';
import Tab from './Tab';

interface TabDefinition<TabValue extends string> {
  label: string;
  value: TabValue;
}

interface TabsProps<TabValue extends string> {
  tabs: TabDefinition<TabValue>[];
  initialTab?: TabValue;
  onTabChange?: (tab: TabValue) => void;
  renderContent: (tab: TabValue) => React.ReactNode;
}

const Tabs = <TabValue extends string>(props: TabsProps<TabValue>) => {
  const { tabs, initialTab, onTabChange, renderContent } = props;
  const [currentTab, setCurrentTab] = useState<TabValue>(
    initialTab || tabs[0].value
  );

  const handleTabClick = (tabValue: TabValue) => {
    setCurrentTab(tabValue);
    onTabChange?.(tabValue);
  };

  return (
    <div>
      <div className="flex gap-4">
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            text={tab.label}
            isActive={tab.value === currentTab}
            onClick={() => handleTabClick(tab.value)}
          />
        ))}
      </div>
      <div className="mt-6">{renderContent(currentTab)}</div>
    </div>
  );
};

export default Tabs;
