import { CheckIcon, XIcon } from '@heroicons/react/outline';
import styles from '@styles/components/form.module.scss';
import { ChangeEvent } from 'react';
import { COLORS } from '../../v3/global';

type Props = {
  title: string;
  value: string;
  disabled?: boolean;
  type?: string;
  placeholder?: string;
  rows?: number;
  maxLength?: number;
  error?: boolean;
  success?: boolean;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
};

const FormTextArea = ({
  value,
  title,
  disabled = false,
  placeholder = '',
  rows = 4,
  maxLength,
  error = false,
  success = false,
  onChange,
}: Props) => {
  return (
    <div
      className={`${styles.formInput} ${error ? styles.formInputError : ''} ${
        success ? styles.formInputSuccess : ''
      }`}
    >
      <label style={{ color: COLORS.TEXT_PRIMARY }} htmlFor={title}>
        {title}
      </label>
      <textarea
        value={value}
        disabled={disabled}
        name={title}
        placeholder={placeholder}
        onChange={onChange}
        rows={rows}
        maxLength={maxLength}
      />
      {error && <XIcon className={styles.icon} />}
      {success && <CheckIcon className={styles.icon} />}
    </div>
  );
};

export default FormTextArea;
