import Select from '@components/V4/Select/Select';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import useLocalTracks from '@modules/MeetingVideo/hooks/useLocalTracks';
import { useAuthState } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { useContext, useRef, useEffect } from 'react';
import VideoWidget from './VideoWidget';

const VideoMeetingPreview = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { avatar } = useAuthState();
  const {
    zoomClient,
    activeCamera,
    activeMicrophone,
    isMicrophoneActive,
    isCameraActive,
    setActiveCamera,
    setActiveMicrophone,
    setIsMicrophoneActive,
    setIsCameraActive,
  } = useContext(ZoomContext);

  const [showToast] = useToast({ duration: 'infinite' });

  useEffect(() => {
    if (!zoomClient) return;

    zoomClient
      .init('en-US', 'Global', {
        patchJsMedia: true,
        stayAwake: true,
        enforceMultipleVideos: true,
        leaveOnPageUnload: true,
        enforceVirtualBackground: true,
      })
      .catch((error) => {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: `Zoom SDK initialization failed: ${error.message}`,
        });
      });
  }, []);

  const {
    micList,
    cameraList,
    toggleMicrophone,
    toggleCamera,
    changeMicrophone,
    changeCamera,
  } = useLocalTracks({
    isCameraActive,
    isMicrophoneActive,
    onActiveMicrophoneChange: setActiveMicrophone,
    onActiveCameraChange: setActiveCamera,
    onSetIsCameraActive: setIsCameraActive,
    onSetIsMicrophoneActive: setIsMicrophoneActive,
    videoRef,
  });

  return (
    <div className="flex flex-col gap-5 laptop:order-2 laptop:min-w-1/2 laptop:w-1/2 laptop:max-w-[calc(50%-clamp(4rem,7.2vw,6.5rem)/2)]">
      <VideoWidget
        onCameraClick={() => toggleCamera(isCameraActive)}
        onMicrophoneClick={() => toggleMicrophone(isMicrophoneActive)}
        ref={videoRef}
        isCameraActive={isCameraActive}
        isMicrophoneActive={isMicrophoneActive}
        isCameraDisabled={!cameraList.length}
        isMicrophoneDisabled={!micList.length}
        avatarUrl={avatar}
      />
      <div className="hidden flex-row gap-5 justify-between laptop:flex laptop:w-full">
        <Select
          options={cameraList.map((item) => ({
            label: item.label,
            value: item.deviceId,
          }))}
          value={activeCamera}
          onSelect={(value) => changeCamera(value as string)}
          label="Video Device"
          className="w-1/2 min-w-[calc(50%-0.625rem)] max-w-[calc(50%-0.625rem)]"
          allowClear={false}
          isDisabled={!cameraList.length}
        />
        <Select
          options={micList.map((item) => ({
            label: item.label,
            value: item.deviceId,
          }))}
          value={activeMicrophone}
          onSelect={(value) => changeMicrophone(value as string)}
          label="Audio Device"
          className="w-1/2 min-w-[calc(50%-0.625rem)] max-w-[calc(50%-0.625rem)]"
          allowClear={false}
          isDisabled={!micList.length}
        />
      </div>
    </div>
  );
};

export default VideoMeetingPreview;
