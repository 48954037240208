import React from 'react';
import MentorCard from '../MentorCard';
import RatingStars from '../Utils/RatingStars';
import './index.styles.scss';
import { Divider } from 'antd';

type Props = {
  mentorFeedback: any;
  selectedTab: string;
};

const StudentFeedbackCard = ({ mentorFeedback, selectedTab }: Props) => {
  return (
    <div style={{ marginBottom: '32px' }}>
      <MentorCard mentorFeedback={mentorFeedback} />
      {/*HOMEWORK*/}
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <p className="feedback-title" style={{ marginRight: '16px' }}>
            Homework
          </p>
          <p>
            <RatingStars
              rating={mentorFeedback?.feedback?.studentHomework?.rate}
            />
          </p>
        </div>
        <div>
          <p className="feedback-description">
            {mentorFeedback?.feedback?.studentHomework?.explanation}
          </p>
        </div>

        <Divider style={{ borderTop: '0.5px solid #B0B0B0' }} />
      </div>

      {/*ENGAGEMENT*/}
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <p className="feedback-title" style={{ marginRight: '16px' }}>
            Engagement
          </p>
          <p>
            <RatingStars
              rating={mentorFeedback?.feedback?.studentEngagement?.rate}
            />
          </p>
        </div>
        <div>
          <p className="feedback-description">
            {mentorFeedback?.feedback?.studentEngagement?.explanation}
          </p>
        </div>

        <Divider style={{ borderTop: '0.5px solid #B0B0B0' }} />
      </div>

      {/*PROGRESS*/}
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <p className="feedback-title" style={{ marginRight: '16px' }}>
            Progress
          </p>
          <p>
            <RatingStars
              rating={mentorFeedback?.feedback?.studentProgress?.rate}
            />
          </p>
        </div>
        <div>
          <p className="feedback-description">
            {mentorFeedback?.feedback?.studentProgress?.explanation}
          </p>
        </div>

        <Divider style={{ borderTop: '0.5px solid #B0B0B0' }} />
      </div>

      {/*CURRENT STATUS*/}
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <p className="feedback-title" style={{ marginRight: '16px' }}>
            Current Status
          </p>
        </div>
        <div>
          <p className="feedback-description">
            {mentorFeedback?.feedback?.currentStatus}
          </p>
        </div>

        <Divider style={{ borderTop: '0.5px solid #B0B0B0' }} />
      </div>

      {/*ACTION PLAN*/}
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <p className="feedback-title" style={{ marginRight: '16px' }}>
            Action Plan
          </p>
        </div>
        <div>
          <p className="feedback-description">
            {mentorFeedback?.feedback?.actionPlan}
          </p>
        </div>

        <Divider style={{ borderTop: '0.5px solid #B0B0B0' }} />
      </div>

      {/*PARENT HELP*/}
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <p className="feedback-title" style={{ marginRight: '16px' }}>
            Parent Help
          </p>
        </div>
        <div>
          <p className="feedback-description">
            {mentorFeedback?.feedback?.parentHelp?.length > 0
              ? mentorFeedback?.feedback?.parentHelp
              : '-'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StudentFeedbackCard;
