import styles from '@styles/components/mentor-feedback.module.scss';
import { FC } from 'react';
import {
  TemplateOptionsIcon,
  iconContent1,
  iconContent2,
  iconContent3,
  iconContent4,
  iconContent5,
  iconContent6,
} from './icons';

const SelectCard: FC<{
  iconContent: any;
  name: string;
  activeValue: { index: number; name: string };
  handleClick: () => void;
}> = ({ iconContent, name, activeValue, handleClick }) => (
  <div
    onClick={handleClick}
    className={`${activeValue.name === name ? styles.cardActive : styles.card}`}
  >
    <TemplateOptionsIcon content={iconContent} name={name} />
    <p className={styles.cardName}>{name}</p>
  </div>
);

export const engagementCardsOptions = [
  { name: 'Unsatisfactory', iconContent: iconContent1 },
  { name: 'Satisfactory', iconContent: iconContent2 },
  { name: 'Excellent', iconContent: iconContent3 },
];

export const progressCardsOptions = [
  { name: 'Behind', iconContent: iconContent4 },
  { name: 'On track', iconContent: iconContent5 },
  { name: 'Ahead', iconContent: iconContent6 },
];

export default SelectCard;
