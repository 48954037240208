import { MyAccountInfo } from '@shared/common';
import { MENTOR_ROLE } from '@shared/constants';
import {
  useUploadProfilePicture,
  useUpdateMyAccount,
  useAuthState,
  useMyMentorData,
  useUpdateMyMentorData,
  useAuthActions,
} from '@shared/react';
import styles from '@styles/pages/mentor/settings.module.scss';
import { Col, message, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { COLORS } from '../../../v3/global';
import MentorBaseSettings from './MentorBaseSettings';
import MentorOtherSettings from './MentorOtherSettings';
import { RouterPrompt } from './RouterPrompt';

const MentorSettings = () => {
  const user: MyAccountInfo = useAuthState();
  const { roles } = useAuthState();
  const { logout } = useAuthActions();
  const isMentor = roles.includes(MENTOR_ROLE);

  const { data: mentorUserData, isLoading } = useMyMentorData({
    enabled: isMentor,
  });
  const { mutate: updateMyMentorData } = useUpdateMyMentorData();
  const { mutate: updateMyAccount } = useUpdateMyAccount({
    onSuccess: () => {},
  });
  const { mutate: uploadImage } = useUploadProfilePicture();
  const [gender, setGender] = useState<string>('');
  const [pronouns, setPronouns] = useState('none');
  const [avatar, setAvatar] = useState('');
  const [fileData, setFileData] = useState<any>();
  const [highSchool, setHighSchool] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [graduationYear, setGraduationYear] = useState<number>();
  const [interests, setInterests] = useState<string[]>([]);
  const [studySubjects, setStudySubjects] = useState<string[]>([]);
  const [weeklyHours, setWeeklyHours] = useState(0);
  const [majors, setMajors] = useState<string[]>([]);
  const [coursesCanTeach, setCoursesCanTeach] = useState<string[]>([]);
  const [universities, setUniversities] = useState<string[]>([]);
  const [maximumActiveEnrollments, setMaximumActiveEnrollments] = useState(0);
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [shortBiography, setShortBiography] = useState('');
  const [biography, setBiography] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const [vatPayer, setVatPayer] = useState<boolean>(false);

  useEffect(() => {
    const applyCurrentInfo = () => {
      setGender(user.gender);
      setAvatar(user.avatar);
      setPronouns(user.pronouns);
      setHighSchool(user.highSchool);
      setCountry(user.country);
      setCity(user.city);
      setGraduationYear(user.graduationYear);
      setInterests(user.interests);
      setStudySubjects(user.studySubjects);
      setPhoneNumber(user.phoneNumber || '');
      setVatPayer(user.rate?.vatPayer ?? false);
    };
    applyCurrentInfo();
  }, []);

  useEffect(() => {
    if (mentorUserData) {
      const { mentorData } = mentorUserData;
      mentorData?.welcomeMessage &&
        setWelcomeMessage(mentorData?.welcomeMessage);
      mentorData?.biography && setBiography(mentorData?.biography);
      mentorData?.shortBiography &&
        setShortBiography(mentorData?.shortBiography);
      !!mentorData?.maximumActiveEnrollments &&
        setMaximumActiveEnrollments(mentorData?.maximumActiveEnrollments);
      !!mentorData?.weeklyHours && setWeeklyHours(mentorData?.weeklyHours);
      mentorData?.majors?.length && setMajors(mentorData?.majors);
      mentorData?.universities?.length &&
        setUniversities(mentorData?.universities);
      mentorData.coursesCanTeach?.length &&
        setCoursesCanTeach(mentorData.coursesCanTeach);
    }
  }, [mentorUserData]);

  const changePassword = () => {
    logout();
  };

  const onImageChange = (event: any) => {
    setFileData(event.target.files[0]);
    setAvatar(window.URL.createObjectURL(event.target.files[0]));
  };

  const saveAll = () => {
    if (fileData) {
      const data = new FormData();
      data.append('image', fileData);
      console.log('file: ', data);
      uploadImage(data);
    }

    updateMyAccount(
      {
        gender,
        pronouns,
        highSchool,
        country,
        city,
        graduationYear,
        interests,
        studySubjects,
        phoneNumber,
      },
      {
        onSuccess: () => {
          message.success('Mentor settings updated successfully');
        },
      }
    );

    updateMyMentorData(
      {
        welcomeMessage,
        biography,
        shortBiography,
        maximumActiveEnrollments,
        weeklyHours,
        universities,
        majors,
        coursesCanTeach,
      },
      {
        onSuccess: () => {
          message.success('User settings updated successfully');
        },
      }
    );
    setIsFormChanged(false);
  };

  const saveCourses = useCallback((majors: string[]) => {
    updateMyMentorData(
      {
        majors,
      },
      {
        onSuccess: () => {
          message.success('Subjects updated successfully');
        },
      }
    );
  }, []);

  return (
    <>
      <RouterPrompt
        when={isFormChanged}
        title="Leave this page"
        cancelText="Leave page"
        okText="Stay on page"
        onOK={() => true}
        onCancel={() => false}
      />
      <div className={styles.page}>
        <Row gutter={[24, 24]}>
          <Col span={24} xl={10}>
            <MentorBaseSettings
              avatar={avatar}
              studySubjects={studySubjects}
              setStudySubjects={setStudySubjects}
              name={user.fullName}
              uploadFn={onImageChange}
              username={user.username}
              changePasswordFn={changePassword}
              gender={gender}
              email={user.email}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              setGender={setGender}
              pronouns={pronouns}
              setPronouns={setPronouns}
              country={country}
              setCountry={setCountry}
              saveAll={saveAll}
              universities={universities}
              setUniversities={setUniversities}
              mentorData={mentorUserData?.mentorData}
              majors={majors}
              setMajors={setMajors}
              coursesCanTeach={coursesCanTeach}
              setCoursesCanTeach={setCoursesCanTeach}
              type="mentor"
              setIsFormChanged={setIsFormChanged}
              saveCourses={saveCourses}
            />
          </Col>

          <Col span={24} xl={14}>
            <div className={styles.section}>
              <MentorOtherSettings
                welcomeMessage={welcomeMessage}
                biography={biography}
                shortBiography={shortBiography}
                setShortBiography={setShortBiography}
                setBiography={setBiography}
                setWelcomeMessage={setWelcomeMessage}
                saveAll={saveAll}
                maximumActiveEnrollments={maximumActiveEnrollments}
                setMaximumActiveEnrollments={setMaximumActiveEnrollments}
                setWeeklyHours={setWeeklyHours}
                weeklyHours={weeklyHours}
                isVatPayer={vatPayer}
                setIsFormChanged={setIsFormChanged}
                isLoading={isLoading}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MentorSettings;
