import { ReactComponent as CloseIcon } from '@assets/icons/new-close-icon.svg';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import { createContext, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';

const ModalContext = createContext({ size: 'medium' });

export interface ModalProps extends BasicProps {
  isOpen: boolean;
  size?: 'small' | 'medium' | 'large' | 'fullScreen';
  hasFullMaxHeight?: boolean;
  hasCloseIcon?: boolean;
  shouldCloseOnBackdropClick?: boolean;
  onClose: () => void;
}

const Modal = (props: ModalProps) => {
  const {
    isOpen,
    size = 'medium',
    className,
    children,
    hasFullMaxHeight = false,
    hasCloseIcon = true,
    shouldCloseOnBackdropClick = true,
    onClose,
  } = props;

  useEffect(() => {
    if (document.body.style.overflowY === 'hidden') {
      return;
    }

    if (isOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.style.width = 'calc(100% - 7px)';
    }

    return () => {
      document.body.style.overflowY = 'unset';
      document.body.style.width = '100%';
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalContext.Provider value={{ size }}>
      <div
        className={mergeClassNames(
          'fixed top-0 left-0 right-0 bottom-0 w-full h-full flex z-[10001] bg-black bg-opacity-50 p-4 overflow-y-auto'
        )}
        onClick={() => shouldCloseOnBackdropClick && onClose()}
      >
        <div
          className={mergeClassNames(
            'relative w-full bg-surfaceObject rounded-lg flex flex-col px-3 py-8 tablet:p-8 m-auto',
            {
              'max-w-122': size === 'small',
              'max-w-196': size === 'medium',
              'max-w-215': size === 'large',
              'max-w-full max-h-full h-full': size === 'fullScreen',
              'max-h-full': hasFullMaxHeight,
            },
            className
          )}
          onClick={(e) => e.stopPropagation()}
        >
          {hasCloseIcon && (
            <CloseIcon
              className="absolute top-4 right-4 w-3 h-3 cursor-pointer text-white hover:text-gray-300"
              onClick={onClose}
            />
          )}
          {children}
        </div>
      </div>
    </ModalContext.Provider>,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById('modal-root')!
  );
};

interface HeaderProps extends BasicProps {
  title?: string;
}

const Header = (props: HeaderProps) => {
  const { className, children, title } = props;

  return (
    <div
      className={mergeClassNames(
        'w-full flex flex-col align-center justify-center px-1 tablet:px-6',
        className
      )}
    >
      {!!title && (
        <h3 className="flex justify-center items-center text-xl font-bold text-white mb-6">
          {title}
        </h3>
      )}
      {children}
    </div>
  );
};

const Body = (props: BasicProps) => {
  const { className, children } = props;

  const { size } = useContext(ModalContext);

  return (
    <div
      className={mergeClassNames(
        'w-full px-1 tablet:px-6 flex flex-col',
        { 'h-full': size === 'fullScreen' },
        className
      )}
    >
      {children}
    </div>
  );
};

const Footer = (props: BasicProps) => {
  const { className, children } = props;

  const { size } = useContext(ModalContext);

  return (
    <div
      className={mergeClassNames(
        'w-full px-1 mt-auto tablet:px-6',
        { 'mt-auto': size === 'fullScreen' },
        className
      )}
    >
      {children}
    </div>
  );
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
