import authHeader from '@services/auth-header';
import { Meeting } from '@shared/common';
import { useMeeting } from '@shared/react';
import styles from '@styles/components/mentor-feedback.module.scss';
import { Button, Modal, Rate, Input, message } from 'antd';
import { COLORS } from 'apps/agora/src/v3/global';
import axios from 'axios';
import { useState } from 'react';
import SelectText from './ModalsSteps/SelectText';

const { TextArea } = Input;

const divStyle = {
  margin: '100px auto',
  maxWidth: '800px',
  textAlign: 'center' as const,
};
const h2Style = {
  fontSize: '30px',
  textAlign: 'center' as const,
  margin: 0,
  fontWeight: 'lighter' as const,
};
const h1Style = {
  fontSize: '42px',
  textAlign: 'center' as const,
  margin: 0,
};
const rateStyle = {
  fontSize: '50px',
  textAlign: 'center' as const,
  margin: '5px 0 30px 0',
};
const pStyle = {
  maxWidth: '30%',
  color: COLORS.TEXT_PRIMARY,
};

type Props = {
  meeting?: Partial<Meeting>;
  meetingId?: string;
  visible: boolean;
  onClose: () => void;
};

const StudentFeedbackModal = (props: Props) => {
  const { meetingId, visible, onClose } = props;

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { data: fetchedMeeting } = useMeeting(meetingId, {
    enabled: !props.meeting,
  });

  const meeting = props.meeting ? props.meeting : fetchedMeeting;
  const name = meeting?.mentor?.fullName || 'your mentor';

  const [optionsSelected, setOptionsSelected] = useState({
    motivating: false,
    inspirational: false,
    patient: false,
    organized: false,
    quickResponse: false,
    adviceOnApplication: false,
    adviceOusideApplication: false,
  });

  type secondStepOptionsType = Array<{
    text: string;
    targetOptionName: options;
  }>;

  type options =
    | 'motivating'
    | 'inspirational'
    | 'patient'
    | 'organized'
    | 'quickResponse'
    | 'adviceOnApplication'
    | 'adviceOusideApplication';

  const closeHandler = () => {
    if (rating !== 0) {
      submitFeedback();
    }
    onClose();
  };

  const toggleOptionsSelected = (option: options) => {
    setOptionsSelected({
      ...optionsSelected,
      [option]: !optionsSelected[option],
    });
  };

  const getTitle = () => {
    if (rating < 3) return 'Bummer… what was the problem?';
    else if (rating < 5) return 'Got it! What can your mentor improve?';
    else return 'Awesome! What did you like about your mentor?';
  };

  const submitFeedback = async () => {
    setIsLoading(true);
    const feedback = {
      rating,
      comment,
      optionsSelected: Object.keys(optionsSelected).filter(
        (option) => optionsSelected[option as options]
      ),
    };

    try {
      await axios.post(
        `/api/meeting/${meeting?._id}/student-feedback`,
        {
          rating: feedback.rating,
          message: `${JSON.stringify(feedback.optionsSelected)}, ${
            feedback.comment
          }`,
          traits: feedback.optionsSelected,
          enrollmentId: meeting?.enrollment,
        },
        { headers: authHeader() }
      );

      setIsLoading(false);
      message.success('Thanks for your feedback!');
    } catch (error) {
      message.error(
        'Something went wrong when trying to register your feedback, please contact the staff.'
      );
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }

    onClose();
  };

  const firstStep = (
    <>
      <h2 style={h2Style}>All done here!</h2>
      <h1 style={h1Style}>How was your meeting with {name}?</h1>
      <Rate style={rateStyle} value={rating} onChange={setRating} />
      <br />
      <span style={pStyle}>
        Telling us how you found your experience with your mentor helps us
        provide you the best studying conditions for your university admission.
        Don't worry, this stays between us.
      </span>
    </>
  );

  const secondStepOptions: secondStepOptionsType = [
    { text: 'Motivating', targetOptionName: 'motivating' },
    { text: 'Inspirational', targetOptionName: 'inspirational' },
    { text: 'Patient', targetOptionName: 'patient' },
    { text: 'Organized', targetOptionName: 'organized' },
    { text: 'Quick Responses', targetOptionName: 'quickResponse' },
    { text: 'Advice on Application', targetOptionName: 'adviceOnApplication' },
    {
      text: 'Advice outside Application',
      targetOptionName: 'adviceOusideApplication',
    },
  ];

  const secondStep = (
    <>
      <Rate style={rateStyle} value={rating} disabled />
      <br />
      <h2 style={h2Style}>{getTitle()}</h2>
      <div
        className={styles.selectOptions}
        style={{ margin: '1.5rem 0 0.7rem 0' }}
      >
        {secondStepOptions.map((option) => (
          <SelectText
            key={option.targetOptionName}
            text={option.text}
            handleClick={() => toggleOptionsSelected(option.targetOptionName)}
            isActive={optionsSelected[option.targetOptionName]}
          />
        ))}
      </div>
      <br />
      <TextArea
        placeholder="We would find it very useful if you feel like telling us more!"
        rows={4}
        style={{ width: '400px' }}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <br />
      <Button
        type="primary"
        style={{ marginTop: '20px' }}
        size="large"
        onClick={submitFeedback}
        loading={isLoading}
      >
        Leave Review
      </Button>
    </>
  );

  return (
    <Modal
      onCancel={closeHandler}
      centered
      open={visible}
      width={'80%'}
      footer={null}
      closable={true}
      maskClosable={false}
      keyboard={false}
    >
      {!!meeting && (
        <div style={divStyle}>{rating ? secondStep : firstStep}</div>
      )}
    </Modal>
  );
};

export default StudentFeedbackModal;
