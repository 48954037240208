import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import ZoomVideo, {
  LocalAudioTrack,
  LocalVideoTrack,
  MediaDevice,
} from '@zoom/videosdk';
import useToast from 'apps/agora/src/hooks/useToast';
import { useEffect, useRef, useState, useContext, RefObject } from 'react';
import { mountDevices, stopTracks } from '../utils/helpers';

interface UseLocalTracksProps {
  videoRef: RefObject<any>;
  initializeVideo?: boolean;
  initializeAudio?: boolean;
  isCameraActive?: boolean;
  isMicrophoneActive?: boolean;
  activeCamera?: string;
  activeMicrophone?: string;
  onActiveMicrophoneChange?: (microphoneId: string) => void;
  onActiveCameraChange?: (cameraId: string) => void;
  onSetIsCameraActive?: (isActive: boolean) => void;
  onSetIsMicrophoneActive?: (isActive: boolean) => void;
}

const useLocalTracks = (props: UseLocalTracksProps) => {
  const {
    initializeVideo = true,
    initializeAudio = true,
    isCameraActive = false,
    isMicrophoneActive = false,
    activeCamera,
    activeMicrophone,
    onSetIsCameraActive,
    onSetIsMicrophoneActive,
    onActiveMicrophoneChange,
    onActiveCameraChange,
    videoRef,
  } = props;

  const [micList, setMicList] = useState<MediaDevice[]>([]);
  const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
  const [initialSetupCompleted, setInitialSetupCompleted] = useState(false);

  const isCameraStateSwitchLoading = useRef<boolean>(true);
  const isMicrophoneStateSwitchLoading = useRef<boolean>(true);
  const streamRef = useRef<MediaStream | undefined>();
  const isMountedRef = useRef(false);

  const localAudioRef = useRef<LocalAudioTrack | null>(null);
  const localVideoRef = useRef<LocalVideoTrack | null>(null);

  const { setCanJoinMeeting } = useContext(ZoomContext);
  const [showToast] = useToast({ duration: 'infinite' });

  const showPermissionsToast = () =>
    showToast({
      variant: 'error',
      messageTitle: 'Error',
      messageBody:
        "Please enable your camera and microphone permissions, followed by a refresh, if you'd like to be seen and heard.",
    });

  const showVideoErrorToast = () =>
    showToast({
      variant: 'error',
      messageTitle: 'Error',
      messageBody:
        "Please check if another app is using your camera, then close it, followed by a refresh, if you'd like to be seen.",
    });

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    isMountedRef.current = true;

    mountDevices()
      .then(async ({ microphones, cameras, stream }) => {
        if (!isMountedRef.current) return;

        streamRef.current = stream;

        if (initializeAudio && microphones.length > 0) {
          const audioTrack = ZoomVideo.createLocalAudioTrack(
            activeMicrophone || microphones[0].deviceId
          );

          localAudioRef.current = audioTrack;

          try {
            await audioTrack.start();

            if (!isMountedRef.current) return;

            await audioTrack.unmute();

            if (!isMountedRef.current) return;

            onSetIsMicrophoneActive?.(true);
            onActiveMicrophoneChange?.(
              activeMicrophone || microphones[0].deviceId
            );
            isMicrophoneStateSwitchLoading.current = false;
            setMicList(microphones);
          } catch (error: any) {
            showToast({
              variant: 'warning',
              messageTitle: 'Error',
              messageBody: error.message,
            });
          }
        }

        if (initializeVideo && videoRef.current && cameras.length > 0) {
          const videoTrack = ZoomVideo.createLocalVideoTrack(
            activeCamera || cameras[0].deviceId
          );

          localVideoRef.current = videoTrack;

          try {
            await videoTrack.start(videoRef.current);

            if (!isMountedRef.current) return;

            onSetIsCameraActive?.(true);
            onActiveCameraChange?.(activeCamera || cameras[0].deviceId);
            isCameraStateSwitchLoading.current = false;
            setCameraList(cameras);
          } catch (error: any) {
            if (error.message === 'Starting videoinput failed') {
              showVideoErrorToast();
            } else if (
              error.name === 'NotAllowedError' &&
              isMountedRef.current
            ) {
              showPermissionsToast();
            } else {
              showToast({
                variant: 'error',
                messageTitle: 'Error',
                messageBody: error.message,
              });
            }
          }
        }

        if (!isMountedRef.current) return;

        setInitialSetupCompleted(true);
      })
      .finally(() => setCanJoinMeeting(true));

    return () => {
      isMountedRef.current = false;

      stopTracks(streamRef.current);

      localAudioRef.current?.stop().catch(console.log);
      localVideoRef.current?.stop().catch(console.log);
    };
  }, []);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const deviceChangeHandler = async () => {
      if (!initialSetupCompleted) {
        return;
      }

      setCanJoinMeeting(false);
      const { microphones, cameras } = await mountDevices(streamRef.current);

      if (microphones.length !== micList.length) {
        if (microphones.length < micList.length) {
          const audioTrack = ZoomVideo.createLocalAudioTrack(
            microphones[0].deviceId
          );

          localAudioRef.current = audioTrack;

          try {
            await audioTrack.start();

            onActiveMicrophoneChange?.(microphones[0].deviceId);

            if (isMicrophoneActive) {
              audioTrack.unmute();
            }
          } catch (error) {
            console.error('Failed to start or unmute new audio track:', error);
          }
        }
        setMicList(microphones);
      }

      if (cameras.length !== cameraList.length) {
        if (cameras.length < cameraList.length) {
          if (isCameraActive) {
            try {
              await localVideoRef.current?.stop();
              stopTracks(streamRef.current, 'video');
            } catch (error) {
              console.error('Failed to stop video:', error);
            }
          }

          const videoTrack = ZoomVideo.createLocalVideoTrack(
            cameras[0].deviceId
          );

          onActiveCameraChange?.(cameras[0].deviceId);

          localVideoRef.current = videoTrack;

          if (isCameraActive) {
            try {
              await videoTrack?.start(videoRef.current);
            } catch (error) {
              console.log({ error });
            }
          }
        }
        setCameraList(cameras);
      }
      setCanJoinMeeting(true);
    };

    navigator.mediaDevices.addEventListener(
      'devicechange',
      deviceChangeHandler
    );

    return () =>
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        deviceChangeHandler
      );
  }, [
    micList,
    cameraList,
    initialSetupCompleted,
    isMicrophoneActive,
    isCameraActive,
  ]);

  const toggleMicrophone = async (isMicrophoneActive: boolean) => {
    if (isMicrophoneStateSwitchLoading.current) return;

    isMicrophoneStateSwitchLoading.current = true;

    try {
      if (isMicrophoneActive) {
        await localAudioRef.current?.mute();
      } else {
        await localAudioRef.current?.unmute();
      }

      onSetIsMicrophoneActive?.(!isMicrophoneActive);
      isMicrophoneStateSwitchLoading.current = false;
    } catch (error: any) {
      if (error.name === 'NotAllowedError') {
        showPermissionsToast();
      }
    }
  };

  const toggleCamera = async (isCameraActive: boolean) => {
    if (!videoRef.current || isCameraStateSwitchLoading.current) return;

    isCameraStateSwitchLoading.current = true;

    try {
      if (isCameraActive) {
        await localVideoRef.current?.stop();
        stopTracks(streamRef.current, 'video');
      } else {
        await localVideoRef.current?.start(videoRef.current);
      }
      onSetIsCameraActive?.(!isCameraActive);

      isCameraStateSwitchLoading.current = false;
    } catch (error: any) {
      if (error.name === 'NotAllowedError') {
        showPermissionsToast();
      }
    }
  };

  const changeMicrophone = async (value: string) => {
    try {
      const audioTrack = ZoomVideo.createLocalAudioTrack(value);
      localAudioRef.current = audioTrack;

      await audioTrack.start();

      if (isMicrophoneActive) {
        await audioTrack.unmute();
        onSetIsMicrophoneActive?.(true);
      }
      onActiveMicrophoneChange?.(value);
    } catch (error: any) {
      if (error.name === 'NotAllowedError') {
        showPermissionsToast();
      }
    }
  };

  const changeCamera = async (value: string) => {
    if (!isCameraActive) {
      const videoTrack = ZoomVideo.createLocalVideoTrack(value);
      localVideoRef.current = videoTrack;
      onActiveCameraChange?.(value);
      return;
    }

    try {
      await localVideoRef.current?.switchCamera(value);
      await onActiveCameraChange?.(value);
    } catch (error: any) {
      if (error.name === 'NotAllowedError') {
        showPermissionsToast();
      }
    }
  };

  return {
    micList,
    cameraList,
    toggleMicrophone,
    toggleCamera,
    changeMicrophone,
    changeCamera,
  };
};

export default useLocalTracks;
