import Accordion from '@components/V4/Accordion';
import SessionModalEmptyState from '../../../SessionModalEmptyState';
import FeedbackEntry from './FeedbackEntry';
import { MentorFeedback, StudentFeedback } from '@shared/common';
import MeetingDetail from '../SessionMeetingsTab/MeetingDetail';
import moment from 'moment';

interface SessionFeedbackTabProps {
  mentorFeedback?: MentorFeedback;
  studentFeedback?: StudentFeedback[];
}

const SessionFeedbackTab = (props: SessionFeedbackTabProps) => {
  const { mentorFeedback, studentFeedback } = props;
  return (
    <div>
      {!mentorFeedback?.message && !studentFeedback?.length ? (
        <SessionModalEmptyState />
      ) : (
        <div className="flex flex-col gap-1">
          <Accordion
            defaultIsOpen
            title={
              <p className="font-raleway font-medium font-xs text-white">
                Mentor Feedback
              </p>
            }
            titleWrapperClassName="bg-surfaceHover p-2"
            className="flex flex-col gap-4 my-2 px-2 max-h-[200px] laptop:max-h-[540px]"
          >
            {!mentorFeedback?.message ? (
              <SessionModalEmptyState />
            ) : (
              <div className="flex flex-col gap-4">
                <MeetingDetail
                  name="Feedback date:"
                  value={
                    mentorFeedback?.createdAt
                      ? moment(mentorFeedback.createdAt).format(
                          'DD.MM.YYYY, HH:mm'
                        )
                      : 'n/a'
                  }
                />

                <FeedbackEntry
                  title="Homework:"
                  score={`${mentorFeedback?.studentHomework?.rate}/5`}
                  description={
                    mentorFeedback?.studentHomework?.explanation ?? ''
                  }
                />

                <FeedbackEntry
                  title="Engagement:"
                  score={`${mentorFeedback?.studentEngagement?.rate}/5`}
                  description={
                    mentorFeedback?.studentEngagement?.explanation ?? ''
                  }
                />

                <FeedbackEntry
                  title="Progress:"
                  score={`${mentorFeedback?.studentProgress?.rate}/5`}
                  description={
                    mentorFeedback?.studentProgress?.explanation ?? ''
                  }
                />
              </div>
            )}
          </Accordion>

          <Accordion
            titleWrapperClassName="bg-surfaceHover p-2"
            title={
              <p className="font-raleway font-medium font-xs text-white">
                Student Feedback
              </p>
            }
            className="flex flex-col gap-4 my-2 px-2 max-h-[80px] laptop:max-h-[140px]"
          >
            {!studentFeedback?.[0]?.message ? (
              <SessionModalEmptyState />
            ) : (
              <p>{studentFeedback?.[0]?.message}</p>
            )}
          </Accordion>
        </div>
      )}
    </div>
  );
};

export default SessionFeedbackTab;
