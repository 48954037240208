import { SESSION_STATUSES, SessionStatus } from '@shared/constants';
import { SessionHistorySessionDTO } from '@shared/types/DTOs/EnrollmentsDTO';
import styles from '@styles/components/table.module.scss';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { COLORS } from '../../../../v3/global';
import GiveFeedbackButtonMentor from '../../../../views/UserView/Home/Common/Buttons/GiveFeedbackButtonMentor';

interface Props {
  session: SessionHistorySessionDTO;
  completedNumber?: number;
  isEven: boolean;
}

const SessionHistoryRow: React.FC<Props> = ({ session, isEven }) => {
  const classes = `${styles.gridTableRow} ${
    isEven ? styles.gridTableRowEven : styles.gridTableRowOdd
  }`;

  const history = useHistory();

  return (
    <>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={`${classes} ${styles.gridTableRowFirst}`}
      >
        {session.students?.map((student: any, index: number) => (
          <p key={student._id}>
            <span
              className="cursor-pointer"
              onClick={() => {
                history.push(`/student-profile/${student._id}`);
              }}
            >
              {student.fullName}
            </span>
            {index + 1 !== session.students.length && (
              <span className="mr-1">,</span>
            )}
          </p>
        ))}
        {/* // .join(', ')}} */}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {session.name}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {session.startDate
          ? moment(session.startDate).format('DD MMM YYYY')
          : 'N/A'}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {Object.values(SessionStatus).includes(session.status)
          ? SESSION_STATUSES[session.status]
          : 'N/A'}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={`${classes} ${styles.gridTableRowLast}`}
      >
        {session.status === SessionStatus.NEEDS_FEEDBACK &&
        session?.meetings?.[0] ? (
          <GiveFeedbackButtonMentor // TODO - This is temporary, resolve this typing
            meeting={session?.meetings?.[0] as any}
          />
        ) : null}
      </div>
    </>
  );
};

export default SessionHistoryRow;
