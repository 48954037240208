import { ReactComponent as ArrowDownIcon } from '@assets/icons/input-arrow-expand.svg';
import { ReactComponent as SelectArrow } from '@assets/icons/input-arrow-expand.svg';
import { Select as SelectInner, SelectProps } from 'antd';
import { getHasValue, mergeClassNames } from 'apps/agora/src/utils/helpers';
import InputError from '../Inputs/InputError';
import InputLabel from '../Inputs/InputLabel';
import './Select.scss';

export interface SelectOption {
  label: string;
  value: string | number | null;
}

interface Props extends Omit<SelectProps, 'size'> {
  variant?: 'primary' | 'secondary';
  showArrow?: boolean;
  label?: string;
  showSearch?: boolean;
  options?: SelectOption[];
  mode?: 'multiple' | 'tags';
  size?: 'default' | 'large';
  value?: any;
  isRequired?: boolean;
  className?: string;
  selectClassName?: string;
  isValid?: boolean;
  isTouched?: boolean;
  isDisabled?: boolean;
  errorText?: string;
  autoFocus?: boolean;
  allowClear?: boolean;
  maxTagCount?: number | 'responsive';
  placeholder?: string;
  onSelect: (value: string | number | null) => void;
  onSearch?: (value: string) => void;
  onBlur?: (value: any) => void;
  onClear?: () => void;
}

const Select = (props: Props) => {
  const {
    children,
    label,
    size,
    options,
    mode = undefined,
    value,
    showArrow = true,
    isRequired,
    isValid,
    isTouched,
    errorText,
    autoFocus,
    isDisabled = false,
    allowClear = true,
    maxTagCount = 'responsive',
    variant = 'primary',
    showSearch = true,
    placeholder = 'Search to select',
    className,
    selectClassName,
    filterOption,
    notFoundContent,
    onSelect,
    onDeselect,
    onSearch,
    onBlur,
    onClear,
  } = props;

  const clearHandler = () => {
    onSelect(null);
    onClear?.();
  };

  return (
    <div className={mergeClassNames('custom-select', size, className)}>
      {label && (
        <InputLabel
          label={label}
          isRequired={isRequired}
          isTouched={isTouched}
          isValid={isValid}
          className={mergeClassNames(
            { 'text-white': variant === 'secondary' },
            'text-xsm'
          )}
        />
      )}

      <SelectInner
        className={mergeClassNames(
          'w-full',
          variant,
          {
            hasValue: getHasValue(value) && isTouched,
            hasError: !isValid && isTouched,
          },
          selectClassName
        )}
        value={value}
        disabled={isDisabled}
        maxTagCount={maxTagCount}
        mode={mode}
        showArrow={showArrow}
        allowClear={allowClear}
        filterOption={
          filterOption
            ? filterOption
            : (input, option) =>
                ((option?.label ?? '') as string)
                  ?.toLowerCase()
                  .includes(input.toLowerCase())
        }
        suffixIcon={<SelectArrow />}
        autoFocus={autoFocus}
        showSearch={showSearch}
        placeholder={
          <div className="placeholder">
            <span>{placeholder}</span>
          </div>
        }
        options={options}
        notFoundContent={notFoundContent}
        onSearch={onSearch}
        onBlur={onBlur}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onClear={clearHandler}
      >
        {children}
      </SelectInner>

      {!isValid && isTouched && errorText && (
        <InputError errorText={errorText} />
      )}
    </div>
  );
};

Select.Option = SelectInner.Option;

export default Select;
