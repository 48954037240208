import styles from '@styles/components/mentor-feedback.module.scss';
import { FC } from 'react';

const NextButton: FC<{ handleClick: () => void }> = ({ handleClick }) => (
  <button
    style={{ color: '#33A9AB', marginLeft: '6px', marginRight: '6px' }}
    className={`${styles.feedbackLink} main-green-link`}
    onClick={handleClick}
  >
    Next
  </button>
);

export default NextButton;
