import { ReactComponent as WorkIcon } from '@assets/icons/profile-icons/work-icon.svg';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import TextArea from '@components/V4/Inputs/Textarea';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ACTIVITY_TYPE,
  ActivityType,
  EMPLOYMENT_TYPE,
  EmploymentType,
  Experience,
  EXPERIENCE_TYPE,
  ExperienceType,
} from '@shared/common';
import { useSaveUserProfile } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { getNumericEnumValues } from 'apps/agora/src/utils/helpers';
import moment from 'moment';
import { useEffect } from 'react';
import { useController, useForm, useWatch } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import { ExamFormProps } from '../../../types';
import StudentFormWrapper from '../../StudentFormWrapper';

interface ResumeTabWorkFormProps extends ExamFormProps {
  jobData?: Experience;
}

const formDataSchema = z.object({
  positionTitle: z
    .string()
    .min(1, { message: 'Please enter a valid school name.' }),
  employer: z.string().min(1, { message: 'Please enter a valid name.' }),
  experienceType: z
    .number()
    .min(0)
    .refine((value) => value in ExperienceType, {
      message: 'Please enter a valid experience type.',
    }),
  employmentType: z
    .number()
    .min(0)
    .refine((value) => value in EmploymentType || value in ACTIVITY_TYPE, {
      message: 'Please enter a valid employment type.',
    }),
  startDate: z.string().min(1, { message: 'Please enter a valid date.' }),
  endDate: z.string().min(1, { message: 'Please enter a valid date.' }),
  description: z.string().optional().default(''),
});

type FormData = z.infer<typeof formDataSchema>;

const ResumeTabWorkForm = (props: ResumeTabWorkFormProps) => {
  const {
    isModeAddNew,
    userId,
    jobData,
    setIsEditMode,
    setIsAddNewItem,
    onCancel,
  } = props;

  const [showToast] = useToast();
  const query = useQueryClient();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    reset,
    resetField,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      positionTitle: jobData?.positionTitle,
      employer: jobData?.employer ?? jobData?.institution,
      employmentType: jobData?.employmentType ?? jobData?.activityType,
      startDate: jobData?.startDate,
      endDate: jobData?.endDate,
      description: jobData?.description,
      experienceType: jobData?.experienceType,
    },
  });

  const { field: startDateField, fieldState: startDateFieldState } =
    useController({
      name: 'startDate',
      control,
    });

  const { field: experienceTypeField, fieldState: experienceTypeFieldState } =
    useController({
      name: 'experienceType',
      control,
    });

  const experienceTypeValue = useWatch({ control, name: 'experienceType' });

  const { field: endDateField, fieldState: endDateFieldState } = useController({
    name: 'endDate',
    control,
  });

  const { field: employmentTypeField, fieldState: employmentTypeFieldState } =
    useController({
      name: 'employmentType',
      control,
    });

  const editButtonHandler = () => {
    setIsEditMode?.(false);
    setIsAddNewItem?.(false);
    reset();
  };

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated your profile.',
      });
      await query.invalidateQueries('/users/:id/profile');
      editButtonHandler();
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Profile could not be saved.',
      });
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      let experience;
      experience = {
        _id: jobData?._id,
        positionTitle: data.positionTitle,
        startDate: data.startDate,
        endDate: data.endDate,
        description: data.description ?? '',
        experienceType: data.experienceType,
      };

      if (data.experienceType === ExperienceType.Work) {
        experience = {
          ...experience,
          employer: data.employer,
          employmentType: data.employmentType,
        };
      } else {
        experience = {
          ...experience,
          institution: data.employer,
          activityType: data.employmentType,
        };
      }
      updateUserProfile({
        experiences: [experience],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string | number>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  const workTypeOptions = {
    Work: {
      options: getNumericEnumValues(EmploymentType)?.map((employment) => ({
        label: EMPLOYMENT_TYPE[employment],
        value: employment,
      })),

      label: 'Employment Type',
    },
    Extra: {
      options: getNumericEnumValues(ActivityType)?.map((activity) => ({
        label: ACTIVITY_TYPE[activity],
        value: activity,
      })),

      label: 'Activity Type',
    },
  };

  const currentType =
    experienceTypeField.value === ExperienceType.Work ? 'Work' : 'Extra';
  const { options: currentOptions, label: currentLabel } =
    workTypeOptions[currentType];

  const experienceTypeFieldSelectedHandler = (value: number) => {
    if (value !== experienceTypeValue) {
      //TODO find a permanent solution for this
      resetField('employmentType', { defaultValue: null as unknown as number });
    }
    experienceTypeField.onChange(value);
  };

  return (
    <StudentFormWrapper
      title="Position"
      isModeAddNew={isModeAddNew}
      onCancel={onCancel}
      icon={<WorkIcon className="min-w-5" />}
      isLoading={isLoading}
      editButtonHandler={editButtonHandler}
      submitHandler={submitHandler}
    >
      <Select
        options={getNumericEnumValues(ExperienceType)?.map((option) => ({
          label: EXPERIENCE_TYPE[option],
          value: option,
        }))}
        onSelect={(value) =>
          experienceTypeFieldSelectedHandler(value as number)
        }
        value={experienceTypeField.value}
        onBlur={experienceTypeField.onBlur}
        isRequired
        label="Experience Type"
        isValid={!experienceTypeFieldState.error}
        isTouched={experienceTypeFieldState.isTouched}
        errorText={experienceTypeFieldState.error?.message}
        size="large"
        allowClear={false}
        autoFocus
      />
      <Select
        options={currentOptions}
        onSelect={(value) => employmentTypeField.onChange(value)}
        value={employmentTypeField.value}
        onBlur={employmentTypeField.onBlur}
        isRequired
        label={currentLabel}
        isValid={!employmentTypeFieldState.error}
        isTouched={employmentTypeFieldState.isTouched}
        errorText={employmentTypeFieldState?.error?.message}
        size="large"
        allowClear={false}
      />

      <Input
        type="text"
        id={'positionTitle'}
        placeholder={''}
        label={'Position Name'}
        isRequired
        isTouched={touchedFields.positionTitle}
        isValid={!errors.positionTitle}
        errorText={errors.positionTitle?.message}
        {...register('positionTitle')}
      />
      <Input
        type="text"
        id={'employer'}
        placeholder={''}
        label={currentType === 'Work' ? 'Employer' : 'Institution/Organisation'}
        isRequired
        isTouched={touchedFields.employer}
        isValid={!errors.employer}
        errorText={errors.employer?.message}
        {...register('employer')}
      />

      <div className="gap-6 laptop:flex">
        <AgoraDatePicker
          value={
            startDateField.value ? moment(startDateField.value) : undefined
          }
          onChange={(value) =>
            startDateField.onChange(moment(value).format('YYYY-MM-DD'))
          }
          onBlur={startDateField.onBlur}
          allowClear={false}
          isRequired
          label="Start Date"
          isValid={!startDateFieldState.error}
          isTouched={startDateFieldState.isTouched}
          errorText={startDateFieldState.error?.message}
        />

        <AgoraDatePicker
          value={endDateField.value ? moment(endDateField.value) : undefined}
          onChange={(value) =>
            endDateField.onChange(moment(value).format('YYYY-MM-DD'))
          }
          onBlur={endDateField.onBlur}
          allowClear={false}
          isRequired
          label="End Date"
          isValid={!endDateFieldState.error}
          isTouched={endDateFieldState.isTouched}
          errorText={endDateFieldState.error?.message}
        />
      </div>
      <TextArea
        id={'description'}
        placeholder={''}
        label={'Description'}
        {...register('description')}
      />
    </StudentFormWrapper>
  );
};

export default ResumeTabWorkForm;
