import { XIcon } from '@heroicons/react/outline';
import { useGetAllCourses } from '@shared/react';
import styles from '@styles/components/reusables/multi-selector.module.scss';
import { useState } from 'react';

type Props = {
  coursesCanTeach: string[];
  setCoursesCanTeach: (course: string[]) => void;
  setIsFormChanged: (arg1: boolean) => void;
};

const CustomCourseSelector = ({
  coursesCanTeach,
  setCoursesCanTeach,
  setIsFormChanged,
}: Props) => {
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  // use query to get all courses
  const { data: courses } = useGetAllCourses();

  const coursesOptions = courses?.filter((course) => {
    return !coursesCanTeach.find((c: string) => c === course._id);
  });

  return (
    <div className={styles.selector}>
      {coursesCanTeach.map((course) => (
        <div
          className={styles.selectorOption}
          key={course}
          onClick={() => {
            setCoursesCanTeach(
              coursesCanTeach.filter((c: string) => c !== course)
            );
            setIsFormChanged(true);
          }}
        >
          {courses?.find((c) => c._id === course)?.title}
          <XIcon className={styles.icon} />
        </div>
      ))}
      <div
        className={styles.selectorAdd}
        onClick={() => setIsOptionsVisible(!isOptionsVisible)}
      >
        Add new
        <div
          className={styles.selectorAddOptions}
          style={{ maxHeight: isOptionsVisible ? '150px' : 0 }}
        >
          {coursesOptions?.map((course) => (
            <div
              key={course._id}
              onClick={() => {
                setCoursesCanTeach([...coursesCanTeach, course._id]);
                setIsFormChanged(true);
              }}
            >
              {course.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomCourseSelector;
