import { ModalProps } from '@components/V4/Modal/Modal';
import { useState } from 'react';
import MultiPageModal from '../../../../components/V4/Modal/MultiPageModal';
import EnrollmentDetailsPage from './EnrollmentDetailsPage/EnrollmentDetailsPage';
import SessionDetailsPage from './SessionDetailsPage/SessionDetailsPage';
import StudentDetailsPage from './StudentDetailsPage/StudentDetailsPage';
import { StudentTab } from './SessionDetailsPage/SessionTabsSection/SessionTabsSection';

interface SessionModalProps extends ModalProps {
  studentId?: string;
  enrollmentId?: string;
  sessionId?: string;
  page?: SessionModalPage;
  tab?: StudentTab;
  onMentorModalOpen?: (mentor: any) => void;
}

export type SessionModalPage =
  | 'mentor'
  | 'student'
  | 'enrollment'
  | 'session'
  | undefined;

const SessionModal = (props: SessionModalProps) => {
  const {
    studentId,
    page,
    enrollmentId: receivedEnrollmentId,
    sessionId: receivedSessionId,
    isOpen,
    onClose,
    onMentorModalOpen,
    tab,
  } = props;

  const [enrollmentId, setEnrollmentId] = useState<string | undefined>(
    receivedEnrollmentId
  );
  const [sessionId, setSessionId] = useState<any>(receivedSessionId);

  const enrollmentModalOpenHandler = (
    id: string,
    goToPage: (page: string) => void
  ) => {
    setEnrollmentId(id);
    goToPage('enrollment');
  };

  const sessionModalOpenHandler = (
    sessionId: string,
    goToPage: (page: string) => void
  ) => {
    setSessionId(sessionId);
    goToPage('session');
  };

  const pages = {
    student: ({ goToPage }: any) => (
      <StudentDetailsPage
        studentId={studentId ?? ''}
        onEnrollmentModalOpen={(id: string) =>
          enrollmentModalOpenHandler(id, goToPage)
        }
        onSessionModalOpen={(id: string) =>
          sessionModalOpenHandler(id, goToPage)
        }
        onMentorModalOpen={(mentor: any) => onMentorModalOpen?.(mentor)}
      />
    ),
    enrollment: ({ goToPage, goBack, isBackButtonActive }: any) => (
      <EnrollmentDetailsPage
        isBackButtonActive={isBackButtonActive}
        enrollmentId={enrollmentId ?? ''}
        onGoBack={goBack}
        onSessionModalOpen={(sessionId: string) =>
          sessionModalOpenHandler(sessionId, goToPage)
        }
      />
    ),
    session: ({ goBack, isBackButtonActive }: any) => (
      <SessionDetailsPage
        isBackButtonActive={isBackButtonActive}
        sessionId={sessionId}
        onGoBack={goBack}
        tab={tab}
      />
    ),
  };

  return (
    <MultiPageModal
      className="min-h-[616px]"
      isOpen={isOpen}
      onClose={onClose}
      initialPage={page || 'student'}
      pages={pages}
    />
  );
};

export default SessionModal;
