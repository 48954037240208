import { ReactNode, useState } from 'react';
import Modal, { ModalProps } from './Modal';

interface MultiPageModalProps extends ModalProps {
  initialPage: string;
  pages: {
    [key: string]: (navigation: {
      isBackButtonActive: boolean;
      goToPage: (page: string) => void;
      goBack: () => void;
      closeModal: () => void;
    }) => ReactNode;
  };
  onClose: () => void;
}

const MultiPageModal = (props: MultiPageModalProps) => {
  const { initialPage, pages, isOpen, onClose, ...rest } = props;

  const [currentPage, setCurrentPage] = useState<string>(initialPage);
  const [history, setHistory] = useState<string[]>([]);

  const goToPage = (page: string) => {
    setHistory((prevHistory) => [...prevHistory, currentPage]);
    setCurrentPage(page);
  };

  const goBack = () => {
    setHistory((prevHistory) => {
      const newHistory = [...prevHistory];
      const previousPage = newHistory.pop();

      if (previousPage) {
        setCurrentPage(previousPage);
      }

      return newHistory;
    });
  };

  const closeModalHandler = () => {
    setCurrentPage(initialPage);
    setHistory([]);
    onClose();
  };

  return (
    <Modal {...rest} isOpen={isOpen} onClose={closeModalHandler}>
      {pages[currentPage]({
        isBackButtonActive: !!history.length,
        goToPage,
        goBack,
        closeModal: closeModalHandler,
      })}
    </Modal>
  );
};

export default MultiPageModal;
