import { ReactComponent as EmptyStateIcon } from '@assets/icons/V4/empty-states/sad-calendar.svg';
import { MeetingModal } from '@components';
import MeetingNeedingFeedbackMentor from '@components/V3/MeetingsNeedingFeedbackMentor/MeetingNeedingFeedbackMentor';
import NextMeeting from '@components/V3/NextMeeting';
import NextMeetingMentor from '@components/V3/NextMeetingsMentor/NextMeetingMentor';
import { useModal } from '@hooks';
import { MeetingWithHomework } from '@shared/common';
import { useAuthState, useMentorStudentMeetings } from '@shared/react';
import { UserProfileDTO } from '@shared/types/DTOs/UserDTO';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import { useEffect, useState } from 'react';
import StudentMainSection from '../ResumeSection';
import moment from 'moment';

interface SessionsTabProps {
  userProfile: Partial<UserProfileDTO>;
}

const SessionsTab = (props: SessionsTabProps) => {
  const { userProfile } = props;
  const { userId: mentorId } = useAuthState();
  const [Modal, show] = useModal(MeetingModal);

  const [filters, setFilters] = useState([
    {
      text: 'All',
      value: '',
    },
  ]);

  const [currentFilter, setCurrentFilter] = useState(filters[0]);

  const { data: meetings } = useMentorStudentMeetings({
    studentId: userProfile._id ?? '',
    enrollmentId: currentFilter.value,
  });

  useEffect(() => {
    if (!userProfile?.enrollments) return;

    const newFilters = Array.from(
      new Map(
        userProfile.enrollments
          ?.filter((enrollment) => enrollment.mentor?._id === mentorId)
          .map((enrollment) => [
            enrollment._id,
            {
              text: enrollment.course?.title ?? '',
              value: enrollment._id ?? '',
            },
          ])
      ).values()
    );

    setFilters((prevFilters) => [{ text: 'All', value: '' }, ...newFilters]);

    if (!newFilters.some((filter) => filter.value === currentFilter.value)) {
      setCurrentFilter({ text: 'All', value: '' });
    }
  }, [userProfile?.enrollments, mentorId]);

  const hasMeetings =
    !!meetings?.needFeedbackMeetings.length ||
    !!meetings?.ongoingMeetings.length ||
    !!meetings?.pastMeetings.length ||
    !!meetings?.upcomingMeetings.length;

  return (
    <section className="flex flex-col gap-4 ">
      <StudentMainSection title="All Sessions">
        <div className="flex flex-wrap gap-4">
          {filters.map((filter) => (
            <p
              key={filter.value}
              onClick={() => setCurrentFilter(filter)}
              className={mergeClassNames(
                'cursor-pointer py-1 px-4 bg-surfaceHover rounded-full',
                {
                  'bg-customPrimary text-black':
                    filter.value === currentFilter.value,
                }
              )}
            >
              {filter.text}
            </p>
          ))}
        </div>
        <div className="flex flex-col gap-4">
          {hasMeetings ? (
            <>
              {meetings?.ongoingMeetings?.map(
                (meeting: MeetingWithHomework) => (
                  <NextMeetingMentor key={meeting._id} meeting={meeting} />
                )
              )}
              {meetings?.upcomingMeetings?.map(
                (meeting: MeetingWithHomework) => (
                  <NextMeetingMentor key={meeting._id} meeting={meeting} />
                )
              )}
              {meetings?.needFeedbackMeetings?.map(
                (meeting: MeetingWithHomework) => (
                  <MeetingNeedingFeedbackMentor
                    key={meeting._id}
                    meeting={meeting}
                  />
                )
              )}
              {meetings?.pastMeetings?.map((meeting: MeetingWithHomework) => (
                <NextMeeting
                  key={meeting._id}
                  meeting={meeting}
                  otherName={meeting.students[0]?.fullName || ''}
                  hideJoinBtn
                />
              ))}
            </>
          ) : (
            <>
              {Modal}
              <EmptyStateCard
                icon={<EmptyStateIcon />}
                text={
                  <>
                    You have no meetings lined up...
                    <br />
                    Keep things rolling by scheduling your next session with
                    your students!
                  </>
                }
                buttonText="Add Meeting"
                onClick={() =>
                  show({
                    date: moment(),
                    isOnHomePage: true,
                  })
                }
              />
            </>
          )}
        </div>
      </StudentMainSection>
    </section>
  );
};

export default SessionsTab;
