import CourseProgressCard from '@components/V4/CourseProgressCard';
import { useAuthState } from '@shared/react';
import { UserProfileDTO } from '@shared/types/DTOs/UserDTO';
import StudentMainSection from '../ResumeSection';

interface YourCoursesSectionProps {
  userProfile: Partial<UserProfileDTO>;
}

const YourCoursesSection = (props: YourCoursesSectionProps) => {
  const { userProfile } = props;

  const { userId } = useAuthState();

  return (
    <StudentMainSection title="Your Courses">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 largeLaptop:grid-cols-3">
        {userProfile.enrollments
          ?.filter((enrollment) => enrollment.mentor?._id === userId)
          .map((enrollment) => (
            <CourseProgressCard key={enrollment._id} enrollment={enrollment} />
          ))}
      </div>
    </StudentMainSection>
  );
};

export default YourCoursesSection;
