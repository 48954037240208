import React from 'react';

interface FeedbackEntryProps {
  title: string;
  score: string;
  description: string;
}

const FeedbackEntry = (props: FeedbackEntryProps) => {
  const { title, score, description } = props;
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between items-center">
        <p className="text-customGrey text-xs leading-5">{title}</p>
        <p className="text-xs font-semibold leading-5">{score}</p>
      </div>
      <p className="text-xs leading-5">{description}</p>
    </div>
  );
};

export default FeedbackEntry;
