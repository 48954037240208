import { ReactComponent as AvatarSkeleton } from '@assets/icons/V4/basic-avatar-skeleton.svg';
import { ReactComponent as SettingsIcon } from '@assets/icons/V4/gear-icon.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/V4/trash-icon.svg';
import IconButton from '@components/V4/IconButton';
import Table from '@components/V4/Table/Table';
import { SessionStatus } from '@shared/constants';
import { useDeleteSession } from '@shared/react';
import { Popconfirm } from 'antd';
import useClientPagination from 'apps/agora/src/hooks/useClientPagination';
import useToast from 'apps/agora/src/hooks/useToast';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import moment from 'moment';
import React from 'react';
import { useMemo } from 'react';

interface SessionsListProps {
  enrollment: { sessions: any[]; mentor: any };
  enrollmentId: string;
  onSessionModalOpen: (session: any) => void;
}

const SessionsList = (props: SessionsListProps) => {
  const { enrollment, enrollmentId, onSessionModalOpen } = props;

  const { sessions, mentor } = enrollment;
  const [showToast] = useToast();

  const { mutate: deleteSession } = useDeleteSession({
    onError() {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Slot could not be deleted.',
      });
    },
  });

  const sortedSessions = useMemo(() => {
    const sessionsWithIndexes = sessions.map((session, index) => {
      let relevantMeetingInfo;

      if (mentor) {
        const isSessionPending =
          session.statuses[session.statuses.length - 1].value ===
          SessionStatus.PENDING;

        if (isSessionPending || !session.relevantMeetingInfo) {
          relevantMeetingInfo = { mentor };
        } else {
          relevantMeetingInfo = session.relevantMeetingInfo;
        }
      }

      return {
        ...session,
        relevantMeetingInfo,
        index,
      };
    });

    return sessionsWithIndexes
      .sort((a, b) => {
        if (
          a?.relevantMeetingInfo?.mentor?._id <
          b?.relevantMeetingInfo?.mentor?._id
        )
          return -1;
        if (
          a?.relevantMeetingInfo?.mentor?._id >
          b?.relevantMeetingInfo?.mentor?._id
        )
          return 1;
        return 0;
      })
      .sort((a, b) => {
        if (a.index < b.index) return 1;
        return -1;
      });
  }, [sessions, mentor]);

  const [paginatedSessions, pagination] = useClientPagination({
    data: sortedSessions,
    shouldOnlyShowPages: true,
  });

  const groupedSessions = useMemo(() => {
    const groupedSessions = paginatedSessions?.reduce((acc, session) => {
      const mentor = session?.relevantMeetingInfo?.mentor?._id;
      if (!acc[mentor]) {
        acc[mentor] = [];
      }
      acc[mentor].push(session);
      return acc;
    }, {});

    return Object.values(groupedSessions);
  }, [paginatedSessions]);

  const tableHeaders = [
    'Session Number',
    'Date',
    'Feedback',
    'Status',
    'Actions',
  ];

  return (
    <Table size="small" pagination={pagination}>
      <Table.Header>
        <Table.Row isHeader>
          {tableHeaders.map((header) => (
            <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {groupedSessions.map((groupedSession: any, index: number) => (
          <React.Fragment key={groupedSession[index]?.[0]?.name}>
            <Table.Row className="border-b-0 border-customgrey border-t-2">
              <Table.Cell
                colSpan={tableHeaders.length}
                className="bg-surfaceHover"
              >
                <div className="flex gap-2">
                  <AvatarSkeleton />
                  {groupedSession[0]?.relevantMeetingInfo?.mentor?.fullName ??
                    'No Mentor'}
                </div>
              </Table.Cell>
            </Table.Row>
            {groupedSession?.map((session: any) => (
              <Table.Row key={session._id}>
                <Table.Cell onClick={() => onSessionModalOpen(session._id)}>
                  {session.name}
                </Table.Cell>
                <Table.Cell>
                  {session.relevantMeetingInfo?.startDate
                    ? moment(session.relevantMeetingInfo?.startDate).format(
                        'DD.MM.YYYY'
                      )
                    : 'n/a'}
                </Table.Cell>
                <Table.Cell
                  className={mergeClassNames({
                    'text-customRed': moment(
                      session.mentorFeedback?.createdAt
                    ).isAfter(
                      moment(
                        session.relevantMeetingInfo?.startDate ??
                          session.mentorFeedback?.createdAt
                      ).add(49, 'hours')
                    ),
                  })}
                >
                  {session.mentorFeedback?.createdAt
                    ? moment(session.mentorFeedback?.createdAt).format(
                        'DD.MM.YYYY'
                      )
                    : 'n/a'}
                </Table.Cell>
                <Table.Cell>
                  {
                    SessionStatus[
                      session.statuses?.[session.statuses.length - 1]?.value
                    ]
                  }
                </Table.Cell>
                <Table.Cell className="flex gap-4">
                  <IconButton
                    onClick={() => onSessionModalOpen(session._id)}
                    icon={<SettingsIcon />}
                    size="small"
                    variant="ghost"
                  />
                  <Popconfirm
                    zIndex={99999}
                    title="Are you sure you want to delete this session?"
                    onConfirm={() =>
                      deleteSession({
                        enrollmentId: enrollmentId,
                        sessionId: session._id,
                      })
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <IconButton
                      variant="ghost"
                      size="small"
                      color="danger"
                      icon={<DeleteIcon />}
                    />
                  </Popconfirm>
                </Table.Cell>
              </Table.Row>
            ))}
          </React.Fragment>
        ))}
      </Table.Body>
    </Table>
  );
};

export default SessionsList;
