import { enrollmentsApis, usersApis } from '../../apis';
import { MENTOR_ROLE } from './../../constants';

import {
  genericMutation,
  genericMutationWithId,
  genericMutationWithParams,
  genericQuery,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useMyMentorData = genericQuery<usersApis.MyMentorData>(
  usersApis.myMentorData
);

export const useUpdateMyMentorData =
  genericMutation<usersApis.UpdateMyMentorData>(
    usersApis.updateMyMentorData,
    usersApis.myMentorData.endpoint
  );

export const useUpdateMentorData =
  genericMutationWithId<usersApis.UpdateMentorData>(
    usersApis.updateMentorData,
    usersApis.getMentorData.endpoint
  );

export const useStudentDetails =
  genericQueryWithId<usersApis.GetStudentDetails>(usersApis.getStudentDetails);

export const useStudentDetailsData = ({ studentId }: { studentId: string }) => {
  return useStudentDetails(studentId);
};

export const useSearchUsers = genericQueryWithParams<usersApis.SearchUsers>(
  usersApis.searchUsers
);

export const useSearchUsersByFullName =
  genericQueryWithParams<usersApis.SearchUsersByFullName>(
    usersApis.searchUsersByFullName
  );

export const useMentors = (extraSearchQuery: any) => {
  return useSearchUsers({
    limit: 500,
    roles: [MENTOR_ROLE],
    ...extraSearchQuery,
  });
};

export const useMentorData = genericQueryWithId<usersApis.GetMentorData>(
  usersApis.getMentorData
);

export const useParentData = genericQueryWithId<usersApis.GetParentData>(
  usersApis.getParentData
);

export const useUserDetails = genericQueryWithId<usersApis.GetUserDetails>(
  usersApis.getUserDetails
);

export const useUserNotes = genericQueryWithId<usersApis.GetUserNotes>(
  usersApis.getUserNotes
);

export const useUpdateUserNote =
  genericMutationWithId<usersApis.UpdateUserNote>(
    usersApis.updateUserNote,
    usersApis.getUserNotes.endpoint
  );

export const useUpdateUser = genericMutationWithId<usersApis.UpdateUser>(
  usersApis.updateUser,
  usersApis.getStudentDetails.endpoint
);

export const useGetAllMentors = genericQueryWithParams<usersApis.GetAllMentors>(
  usersApis.getAllMentors
);

export const useGetAllStudents =
  genericQueryWithParams<usersApis.GetAllStudents>(usersApis.getAllStudents);

export const useResetUserActivationLink =
  genericMutation<usersApis.ResetUserActivationLink>(
    usersApis.resetUserActivationLink
  );

export const useSearchStudents =
  genericQueryWithParams<usersApis.SearchStudents>(usersApis.searchStudents);

export const useSetVatPayerStatus =
  genericMutationWithId<usersApis.SetVatPayerStatus>(
    usersApis.setVatPayerStatus,
    usersApis.getStudentDetails.endpoint,
    usersApis.me.endpoint
  );

export const useGetUserProfile = genericQueryWithId<usersApis.GetUserProfile>(
  usersApis.getUserProfile
);

export const useSaveUserProfile =
  genericMutationWithId<usersApis.SaveUserProfile>(
    usersApis.saveUserProfile,
    '/users/:id/profile'
  );

export const useGetStudentStatistics =
  genericQuery<usersApis.GetStudentStatistics>(usersApis.getStudentStatistics);

export const useSearchUsersV2 = genericQueryWithParams<usersApis.SearchUsersV2>(
  usersApis.searchUsersV2
);

export const useEditUserPermissions =
  genericMutationWithId<usersApis.EditUserPermissions>(
    usersApis.editUserPermissions
  );

export const useGetUserPermissions =
  genericQueryWithId<usersApis.GetUserPermissions>(
    usersApis.getUserPermissions
  );

export const useCheckIfUserExists =
  genericQueryWithParams<usersApis.IsUserOrParentEmailRegistered>(
    usersApis.isUserOrParentEmailRegistered
  );

export const useAddVirtualBackground =
  genericMutation<usersApis.AddVirtualBackground>(
    usersApis.addVirtualBackground,
    usersApis.addVirtualBackground.endpoint
  );

export const useDeleteVirtualBackground =
  genericMutation<usersApis.DeleteVirtualBackground>(
    usersApis.deleteVirtualBackground,
    usersApis.deleteVirtualBackground.endpoint
  );

export const useGetVirtualBackgrounds =
  genericQuery<usersApis.GetVirtualBackgrounds>(
    usersApis.getVirtualBackgrounds
  );
