import styles from '@styles/components/mentor-feedback.module.scss';
import { Input } from 'antd';
import React, { FC } from 'react';
import { COLORS } from '../../../../../v3/global';
import { questionMark } from './icons';

const InputItem: FC<{
  title?: string;
  subtitle?: string;
  description?: any;
  value: string;
  handleChange: any;
  height?: string;
  width?: string;
  descriptionWidth?: string;
  minCharactersRequired?: number;
  required?: boolean;
}> = ({
  title,
  subtitle,
  description,
  value,
  handleChange,
  height,
  width,
  descriptionWidth,
  minCharactersRequired,
  required,
}) => {
  // Count characters excluding spaces
  const characterCountWithoutSpaces = value.replace(/\s/g, '').length;

  return (
    <div style={{ marginBottom: '20px' }} className={styles.feedbackQuestion}>
      <div className={styles.feedbackQuestionTitle}>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {description ? (
              <div
                style={{ marginRight: '4px' }}
                className={styles.feedbackQuestionMark}
              >
                {questionMark}
                <div
                  className={styles.feedbackQuestionMarkDescription}
                  style={{ width: descriptionWidth || '35rem' }}
                >
                  {description}
                </div>
              </div>
            ) : null}

            <h4
              style={{
                margin: 0,
                color: COLORS.TEXT_PRIMARY,
              }}
            >
              {title}
            </h4>
          </div>
          {subtitle ? (
            <span
              style={{
                margin: 0,
                fontSize: '14px',
                color: COLORS.TEXT_PRIMARY,
              }}
            >
              {subtitle}
            </span>
          ) : null}
        </div>
      </div>
      <Input.TextArea
        showCount
        value={value}
        onChange={handleChange}
        placeholder="Type here"
        className={styles.feedbackQuestionInput}
        style={{
          height: height || '10rem',
          marginBottom: '0px',
          width: width || '100%',
        }}
      />
      {minCharactersRequired &&
      characterCountWithoutSpaces < minCharactersRequired ? (
        <span style={{ color: 'rgb(255, 77, 79)' }}>
          A minimum of {minCharactersRequired} characters is required
        </span>
      ) : null}
      {required && characterCountWithoutSpaces < 1 ? (
        <span style={{ color: 'rgb(255, 77, 79)' }}>Required*</span>
      ) : null}
    </div>
  );
};

export default InputItem;
