import React from 'react';

interface StudentAsideInfoProps {
  label: string;
  value: string;
}

const StudentAsideInfo = (props: StudentAsideInfoProps) => {
  const { label, value } = props;
  return (
    <div>
      <p className="text-sm text-customGrey">{label}</p>
      <p className="text-sm">{value}</p>
    </div>
  );
};

export default StudentAsideInfo;
