import AgoraBigSelector from '@components/V3/Utils/InputsV3/AgoraBigSelector';
import { zodResolver } from '@hookform/resolvers/zod';
import { Enrollment } from '@shared/common';
import { useMentors, useUpdateEnrollment } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import SessionModalFormWrapper from '../../SessionModalFormWrapper';

interface ChangeMentorFormProps {
  defaultData: Enrollment;
  onCloseEditMode: () => void;
}

const formDataSchema = z.object({
  mentor: z.string().min(1, { message: 'Please select a mentor.' }),
});

type FormData = z.infer<typeof formDataSchema>;

const ChangeMentorForm = (props: ChangeMentorFormProps) => {
  const { defaultData, onCloseEditMode } = props;

  const [showToast] = useToast();

  const { data: mentorsData, isLoading: mentorsLoading } = useMentors({
    isDezactivated: 'unchecked',
  });

  const { handleSubmit, trigger, setValue, control, getValues } =
    useForm<FormData>({
      resolver: zodResolver(formDataSchema),
      reValidateMode: 'onBlur',
      mode: 'onBlur',
    });

  const { field: mentorField, fieldState: mentorFieldState } = useController({
    name: 'mentor',
    control,
  });

  const { mutate: updateEnrollment, isLoading } = useUpdateEnrollment({
    onSuccess: async () => {
      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated the mentor.',
      });
      onCloseEditMode();
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Mentor could not be updated.',
      });
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      updateEnrollment({
        enrollmentId: defaultData._id,
        mentorId: data.mentor,
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  return (
    <SessionModalFormWrapper
      title={'Mentor'}
      isLoading={isLoading}
      onCancel={onCloseEditMode}
      submitHandler={submitHandler}
    >
      <AgoraBigSelector
        value={mentorField.value}
        onSelect={(value) => mentorField.onChange(value)}
        options={mentorsData?.users?.map((mentor) => ({
          label: mentor.fullName,
          value: mentor._id,
        }))}
        onBlur={mentorField.onBlur}
        labelKey="label"
        valueKey="value"
        label="Mentor"
        isLoading={mentorsLoading}
        isValid={!mentorFieldState.error}
        isTouched={mentorFieldState.isTouched}
        errorText={mentorFieldState.error?.message}
        isRequired
      />
    </SessionModalFormWrapper>
  );
};

export default ChangeMentorForm;
