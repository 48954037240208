import { UserProfileDTO } from '@shared/types/DTOs/UserDTO';
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import ProfileBanner from '../../ProfileBanner/ProfileBanner';
import CoursesTab from './CoursesTab/CoursesTab';
import ExamsTab from './ExamsTab/ExamsTab';
import GoalsTab from './GoalsTab/GoalsTab';
import ResumeTab from './ResumeTab/ResumeTab';
import SessionsTab from './SessionsTab/SessionsTab';
import TabItem from './TabItem';

interface StudentMainProps {
  userProfile?: Partial<UserProfileDTO>;
  isMentorViewer: boolean;
}

const baseTabs = ['Goals', 'Exams', 'Resume'];

const StudentMain = (props: StudentMainProps) => {
  const { userProfile, isMentorViewer } = props;

  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const queryTab = queryParams.get('tab');

  const [tabs, setTabs] = useState(baseTabs);
  const [currentTab, setCurrentTab] = useState(queryTab ?? tabs[0]);

  useEffect(() => {
    if (isMentorViewer) {
      setTabs([...baseTabs, 'Courses', 'Sessions']);
    } else {
      setTabs(baseTabs);
    }
  }, [isMentorViewer]);

  const displayTabPageByCurrentTab = () => {
    if (!userProfile) return;
    switch (currentTab) {
      case 'Resume':
        return (
          <ResumeTab
            isMentorViewer={isMentorViewer}
            userId={userProfile._id ?? ''}
            userProfile={userProfile}
          />
        );
      case 'Exams':
        return (
          <ExamsTab
            userId={userProfile._id ?? ''}
            languageExams={userProfile?.languageExams ?? []}
            isMentorViewer={isMentorViewer}
          />
        );
      case 'Goals':
        return (
          <GoalsTab
            userId={userProfile._id ?? ''}
            userProfile={userProfile}
            isMentorViewer={isMentorViewer}
          />
        );
      case 'Courses':
        return <CoursesTab userProfile={userProfile} />;
      case 'Sessions':
        return <SessionsTab userProfile={userProfile} />;
      default:
        return null;
    }
  };

  const tabItemHandler = (tabName: string) => {
    if (queryParams.get('tab')) {
      queryParams.delete('tab');
    }

    history.push({
      pathname: location.pathname,
      search: queryParams.toString(),
    });

    setCurrentTab(tabName);
  };

  return (
    <section className="flex flex-col gap-4 tablet:w-2/3">
      {!isMentorViewer && <ProfileBanner hasSubBanner={false} />}
      <section className="flex flex-col bg-surfaceObject rounded-lg overflow-hidden ">
        <nav>
          <ul className="flex flex-wrap justify-between bg-surfaceBackground border-b border-b-customPrimary tablet:justify-start">
            {tabs.map((tab) => (
              <TabItem
                onClick={() => tabItemHandler(tab)}
                key={tab}
                tabName={tab}
                isActive={tab === currentTab}
              />
            ))}
          </ul>
        </nav>

        <section className="p-4 tablet:p-6 laptop:p-8">
          {displayTabPageByCurrentTab()}
        </section>
      </section>
    </section>
  );
};

export default StudentMain;
