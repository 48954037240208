import { CloseModalProps } from '@shared/common';
import styles from '@styles/components/mentor-feedback.module.scss';
import { Modal } from 'antd';

type Props = CloseModalProps & {
  handleOk: () => void;
  okText: string;
  cancelText: string;
};

const SnoozeModal = ({
  visible,
  hide,
  handleOk,
  okText,
  cancelText,
}: Props) => {
  return (
    <Modal
      className={styles.snoozeModal}
      centered
      width="30rem"
      visible={visible}
      onOk={() => {
        handleOk();
        hide();
      }}
      okText={okText}
      onCancel={hide}
      cancelText={cancelText}
      closable={true}
    >
      <p style={{ fontSize: '1.5rem' }}>
        Are you sure you want to Snooze Feedback for 24h?
      </p>
    </Modal>
  );
};

export default SnoozeModal;
