import { ReactComponent as CameraIconActive } from '@assets/icons/video-icons/camera-icon-active.svg';
import { ReactComponent as CameraIcon } from '@assets/icons/video-icons/camera-icon.svg';
import { ReactComponent as EndCallIcon } from '@assets/icons/video-icons/end-call-icon.svg';
import { ReactComponent as HandIcon } from '@assets/icons/video-icons/hand-icon.svg';
import { ReactComponent as MicrophoneIconActive } from '@assets/icons/video-icons/microphone-icon-active.svg';
import { ReactComponent as MicrophoneIcon } from '@assets/icons/video-icons/microphone-icon.svg';

import { ReactComponent as OptionsIcon } from '@assets/icons/video-icons/options-icon.svg';
import { ReactComponent as ShareScreenIconActive } from '@assets/icons/video-icons/share-screen-icon-active.svg';
import { ReactComponent as ShareScreenIcon } from '@assets/icons/video-icons/share-screen-icon.svg';
import { ReactComponent as ThumbsUpIcon } from '@assets/icons/video-icons/thumbs-up-icon.svg';
import IconButton from '@components/V4/IconButton';
import Tooltip from '@components/V4/Tooltip';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import { hasScreenShareCapability } from '@modules/MeetingVideo/utils/helpers';
import { CommandChannel, CommandChannelMsg } from '@zoom/videosdk';
import useToast from 'apps/agora/src/hooks/useToast';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { VideoMeetingModal } from './VideoMeeting';

interface VideoActionButtonProps {
  isShareScreenDisabled?: boolean;
  setActiveModal: React.Dispatch<React.SetStateAction<VideoMeetingModal>>;
  onCameraButtonClick: () => void;
  onMicrophoneButtonClick: () => void;
  onRaiseHandClick: (senderId: number) => void;
  onThumbsUpClick: (
    senderId: number,
    senderName: string,
    timestamp: number
  ) => void;
  onShareScreenClick: () => Promise<void>;
}

type VideoActionButton = {
  icon: ReactNode;
  onClickHandler: () => void;
  isDanger?: boolean;
  isActive?: boolean;
  className?: string;
  isDisabled?: boolean;
  tooltipText: {
    active: string;
    inactive: string;
  };
};

const VideoActionButtons = (props: VideoActionButtonProps) => {
  const {
    isShareScreenDisabled,
    setActiveModal,
    onRaiseHandClick,
    onCameraButtonClick,
    onMicrophoneButtonClick,
    onThumbsUpClick,
    onShareScreenClick,
  } = props;

  const { isMicrophoneActive, isCameraActive, zoomClient, stream } =
    useContext(ZoomContext);
  const [isShareScreenActive, setIsShareScreenActive] = useState(false);
  const [isHandUpActive, setIsHandUpActive] = useState(false);

  const commandChannelRef = useRef<typeof CommandChannel>();

  const [showToast] = useToast();

  useEffect(() => {
    if (!zoomClient) return;

    commandChannelRef.current = zoomClient.getCommandClient();

    const passivelyStopShareHandler = () => {
      setIsShareScreenActive(false);
    };

    zoomClient.on('passively-stop-share', passivelyStopShareHandler);

    return () => {
      zoomClient.off('passively-stop-share', passivelyStopShareHandler);
    };
  }, []);

  const handleShowModal = (
    modalPage: 'end-meeting' | 'settings' | undefined
  ) => {
    setActiveModal(modalPage);
  };

  const handleShareScreenButtonClick = async () => {
    try {
      await onShareScreenClick();
      setIsShareScreenActive((prev) => !prev);
    } catch (error) {
      console.log(error);
    }
  };

  const handleHandButtonClick = async () => {
    if (!commandChannelRef.current || !zoomClient) return;

    try {
      await commandChannelRef.current.send('hand-raise');

      const sessionInfo = zoomClient.getSessionInfo();

      onRaiseHandClick(sessionInfo.userId);
      setIsHandUpActive((prev) => !prev);
    } catch (error: any) {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: error.type,
      });
    }
  };

  const handleThumbsUpButtonClick = async () => {
    if (!commandChannelRef.current || !zoomClient) return;

    try {
      const command = (await commandChannelRef.current.send(
        'thumbs-up'
      )) as CommandChannelMsg;

      const sessionInfo = zoomClient.getSessionInfo();

      onThumbsUpClick(
        sessionInfo.userId,
        sessionInfo.userName,
        command.timestamp
      );
    } catch (error: any) {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: error.type,
      });
    }
  };

  const handleOptionsButtonClick = () => {
    handleShowModal('settings');
  };

  const handleEndMeetingButton = () => {
    handleShowModal('end-meeting');
  };

  const videoActionButtons: VideoActionButton[] = [
    {
      icon: isMicrophoneActive ? <MicrophoneIcon /> : <MicrophoneIconActive />,
      isDanger: true,
      onClickHandler: onMicrophoneButtonClick,
      isActive: !isMicrophoneActive,
      tooltipText: {
        active: 'Unmute microphone',
        inactive: 'Mute microphone',
      },
    },
    {
      icon: isCameraActive ? <CameraIcon /> : <CameraIconActive />,
      isDanger: true,
      onClickHandler: onCameraButtonClick,
      isActive: !isCameraActive,
      tooltipText: {
        active: 'Turn on Camera',
        inactive: 'Pause Camera',
      },
    },
    {
      icon: !isShareScreenActive ? (
        <ShareScreenIcon />
      ) : (
        <ShareScreenIconActive />
      ),
      onClickHandler: handleShareScreenButtonClick,
      isActive: isShareScreenActive,
      isDisabled: isShareScreenDisabled || !hasScreenShareCapability(),
      tooltipText: {
        active: 'Stop Screen Share',
        inactive: 'Screen Share',
      },
    },
    {
      icon: <HandIcon />,
      onClickHandler: handleHandButtonClick,
      isActive: isHandUpActive,
      tooltipText: {
        active: 'Lower Hand',
        inactive: 'Raise Hand',
      },
    },
    {
      icon: <ThumbsUpIcon />,
      onClickHandler: handleThumbsUpButtonClick,
      tooltipText: {
        active: '',
        inactive: 'Thumbs Up',
      },
    },
    {
      icon: <OptionsIcon />,
      onClickHandler: handleOptionsButtonClick,
      tooltipText: {
        active: '',
        inactive: 'More Settings',
      },
    },
    {
      icon: <EndCallIcon />,
      className:
        'w-14 h-8 bg-customRed text-surfaceHover hover:bg-customRedAccent tablet:w-16 tablet:h-10',
      onClickHandler: handleEndMeetingButton,
      tooltipText: {
        active: '',
        inactive: 'End Meeting',
      },
    },
  ];

  return (
    <div className="flex gap-2 laptop:gap-6">
      {videoActionButtons.map((button, index) => (
        <Tooltip
          text={
            button.isActive
              ? button.tooltipText.active
              : button.tooltipText.inactive
          }
          placement="top"
          key={index}
        >
          <IconButton
            icon={button.icon}
            variant="secondary"
            className={mergeClassNames(
              'w-8 h-8 tablet:w-10 tablet:h-10',
              button.className
            )}
            isDisabled={button.isDisabled}
            onClick={button.onClickHandler}
            size="large"
            color={button.isDanger ? 'danger' : 'default'}
            isActive={button.isActive}
          />
        </Tooltip>
      ))}
    </div>
  );
};

export default VideoActionButtons;
