import { useAuthState } from '@shared/react';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import { useEffect } from 'react';
import uncaught from 'uncaught';

const useErrLogs = () => {
  const { userId } = useAuthState();

  const impersonateId = localStorage.getItem('impersonate-user-id');

  useEffect(() => {
    uncaught.start();
    uncaught.addListener((error: Error) => {
      console.error('Uncaught error or rejection: ', error?.message);

      axios
        .post('/api/err/log', {
          error: error?.message,
          userAgent: navigator.userAgent,
          user: impersonateId ?? userId,
          location: window.location.href,
        })
        .catch(() => {
          uncaught.removeAllListeners();
        });
    });

    const history = createBrowserHistory();
    // return history.listen(() => {
    //   (window as any).fathom('trackPageview');
    // });
  }, []);
};

export default useErrLogs;
