import { ReactComponent as LogoIcon } from '@assets/icons/logo-no-text.svg';
import Button from '@components/V4/Button';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import { TRANSCRIPTION_LANGUAGES } from '@modules/MeetingVideo/utils/constants';
import { getWaitingScreenMeetingDetails } from '@shared/apis/video.apis';
import { MeetingStatus } from '@shared/constants';
import { useAuthState, useStartMeeting } from '@shared/react';
import { storage } from '@shared/react/UniversalStorage';
import { documentUrl } from '@shared/utils/string';
import { LiveTranscriptionLanguage } from '@zoom/videosdk';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import { z } from 'zod';

type Props = {
  setIsWaitingRoom: (isWaitingRoom: boolean) => void;
};

const formSchema = z.object({
  language: z
    .string()
    .min(1, { message: 'Please select a language.' })
    .refine(
      (lang) =>
        Object.values(LiveTranscriptionLanguage).includes(
          lang as LiveTranscriptionLanguage
        ),
      {
        message: 'Please select a valid language.',
      }
    ),
});

type FormData = z.infer<typeof formSchema>;

const VideoMeetingDetails = (props: Props) => {
  const { setIsWaitingRoom } = props;

  const { isMentor, userId } = useAuthState();

  const queryClient = useQueryClient();

  const {
    meetingDetails,
    zoomClient,
    canJoinMeeting,
    webSocket,
    isSocketConnected,
    transcriptLanguage,
    setTranscriptLanguage,
    setIsMeetingLoading,
  } = useContext(ZoomContext);

  const location = useLocation();
  const history = useHistory();

  const { meetingId } = useParams<{ meetingId: string }>();

  const { handleSubmit, trigger, setValue, control, getValues } =
    useForm<FormData>({
      resolver: zodResolver(formSchema),
      reValidateMode: 'onBlur',
      mode: 'onBlur',
    });

  const { field: languageField, fieldState: languageFieldState } =
    useController({
      name: 'language',
      control,
    });

  const { name, startDate, status, agenda, googleDrive } = meetingDetails || {};

  const { mutate: startMeeting } = useStartMeeting(meetingId || '', {
    onSuccess: () => {
      queryClient.invalidateQueries(getWaitingScreenMeetingDetails.endpoint);
    },
  });

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      setTranscriptLanguage(data.language as LiveTranscriptionLanguage);
      await handleJoinMeeting();
    }
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    //TODO remove this
    const token = storage.getItem('auth0token');

    if (!webSocket || !isSocketConnected || !token || !userId) return;

    webSocket.send(
      JSON.stringify({
        type: 'join-waiting-room',
        meetingId,
        token: `Bearer ${token}`,
      })
    );

    return () => {
      webSocket.send(
        JSON.stringify({
          type: 'leave-waiting-room',
          meetingId,
          token: `Bearer ${token}`,
        })
      );
    };
  }, [isSocketConnected, userId]);

  const handleJoinMeeting = async () => {
    if (!meetingDetails) return;

    setIsMeetingLoading(true);
    setIsWaitingRoom(false);

    await zoomClient?.join(
      meetingDetails.accessTokenData.session_name,
      meetingDetails.accessToken,
      meetingDetails.accessTokenData.name
    );

    if (status === MeetingStatus.SCHEDULED) {
      startMeeting({});
    }

    const params = new URLSearchParams(location.search);
    params.append('inMeeting', 'true');

    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });

    setIsMeetingLoading(false);
  };

  return (
    <div
      className="
          flex flex-col gap-6 items-center px-4 pb-6
          tablet:px-0 tablet:order-1
          laptop:items-start laptop:w-1/2 laptop:min-w-1/2 laptop:max-w-[calc(50%-clamp(4rem,7.2vw,6.5rem)/2)]
          largeLaptop:max-w-[29rem]
        "
    >
      <LogoIcon />

      <h1 className="font-raleway text-xl font-bold">{name}</h1>

      <p className="text-xs font-semibold leading-5">
        Date & Time:{' '}
        <span className="font-normal">
          {moment(startDate).format('DD MMM YYYY, HH:mm')}
        </span>
      </p>

      {!!agenda && (
        <p className="text-xs text-center font-semibold leading-5 laptop:text-left">
          Meeting Agenda: <span className="font-normal">{agenda}</span>
        </p>
      )}

      {isMentor && (
        <div>
          <p className="text-xs text-center font-bold leading-5 laptop:text-left mb-2">
            Meeting Language <span className="text-customRed">*</span>
          </p>
          <Select
            showSearch={false}
            options={TRANSCRIPTION_LANGUAGES}
            allowClear={false}
            value={languageField.value}
            onSelect={(value) => languageField.onChange(value)}
            onBlur={languageField.onBlur}
            isValid={!languageFieldState.error}
            isTouched={languageFieldState.isTouched}
            errorText={languageFieldState.error?.message}
          />
        </div>
      )}

      <div className="flex justify-between gap-4">
        <Button
          disabled={!canJoinMeeting}
          variant="primary"
          buttonText="Join Meeting"
          className="h-8"
          onClick={isMentor ? submitHandler : handleJoinMeeting}
        />
        <a
          href={`https://drive.google.com/drive/folders/${googleDrive}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            variant="secondary"
            buttonText={isMentor ? 'Open Student Drive' : 'Open Drive'}
            className="h-8"
          />
        </a>
        {meetingDetails?.homeworkFileId && (
          <a
            href={documentUrl(meetingDetails.homeworkFileId)}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              variant="secondary"
              buttonText="View Homework"
              className="h-8"
            />
          </a>
        )}
      </div>
    </div>
  );
};

export default VideoMeetingDetails;
