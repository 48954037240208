import styles from '@styles/components/mentor-feedback.module.scss';
import React from 'react';

type Props = {
  totalSteps: number;
  currentStep: number;
};

const ProgressBar: React.FC<Props> = ({ totalSteps, currentStep }) => {
  const items = [...new Array(totalSteps)];

  // first step is not included

  return (
    <div className={styles.progressBar}>
      {currentStep > 0 &&
        items.map((item, index) => (
          <div
            className={`${styles.progressBarItem} ${
              currentStep - 1 >= index
                ? styles.progressBarItemComplete
                : styles.progressBarItemIncomplete
            }`}
            key={`progress-item-${index}`}
          ></div>
        ))}
    </div>
  );
};

export default ProgressBar;
