import Button from '@components/V4/Button';
import { useContext, useState } from 'react';
import { StudentDetailsPageContext } from '../StudentDetailsPage';
import StudentDetailsForm from './StudentDetailsForm';

const StudentDetailsSection = () => {
  const [isEditMode, setIsEditMode] = useState(false);

  const { student } = useContext(StudentDetailsPageContext);

  const studentDetailsArray = [
    [
      { label: 'Mail:', value: student?.email },
      { label: 'Phone:', value: student?.phoneNumber },
    ],
    [
      { label: 'Parent Mail:', value: student?.parentData?.email },
      { label: 'Parent Phone:', value: student?.parentData?.phoneNumber },
      { label: 'Parent Name:', value: student?.parentData?.fullName },
    ],
    [
      {
        label: 'Location:',
        value: `${student?.country || 'n/a'}, ${student?.city || 'n/a'}`,
      },
      { label: 'Timezone:', value: student?.timezone },
      { label: 'Language:', value: student?.preferredLanguage },
    ],
  ];

  const editModeChangeHandler = () => {
    setIsEditMode((editMode) => !editMode);
  };

  return isEditMode ? (
    <StudentDetailsForm onCloseEditMode={editModeChangeHandler} />
  ) : (
    <>
      <div className="flex justify-between mb-2">
        <p className="text-xs font-bold">Contact Info</p>
        <Button buttonText="Edit" onClick={editModeChangeHandler} />
      </div>
      <div className="w-full flex gap-x-8 justify-between">
        {studentDetailsArray.map((studentDetails, index) => (
          <div key={`column-${index}`} className="flex flex-col gap-2 ">
            {studentDetails.map((studentDetail, index) => (
              <span
                key={`row-${index}`}
                className="flex text-customGrey font-raleway text-xs"
              >
                <span className="min-w-max">{studentDetail.label}</span>
                <span className="text-white ml-2 break-all">
                  {studentDetail.value ?? 'n/a'}
                </span>
              </span>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default StudentDetailsSection;
