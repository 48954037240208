import { ReactComponent as SortIcon } from '@assets/icons/V4/sort-icon.svg';
import { ReactComponent as ArrowTailless } from '@assets/icons/select-arrow.svg';

import { Pagination as PaginationInner } from 'antd';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import './Table.scss';

import { useEffect } from 'react';
import Select from '../Select/Select';

interface TableProps extends BasicProps {
  tableClassName?: string;
  size?: 'small' | 'default';
  pagination?: PaginationProps;
}

const Table = (props: TableProps) => {
  const {
    className,
    tableClassName,
    children,
    pagination,
    size = 'default',
  } = props;

  return (
    <div className={mergeClassNames(className)}>
      <table
        className={mergeClassNames(
          'min-w-full border-collapse table-auto bg-transparent text-customGrey font-bold text-xs',
          {
            '[&_td]:p-4 [&_th]:p-4': size === 'default',
            '[&_td]:p-2.5 [&_th]:p-2.5': size === 'small',
          },
          tableClassName
        )}
      >
        {children}
      </table>
      {!!pagination && <Pagination size={size} {...pagination} />}
    </div>
  );
};

const Header = (props: BasicProps) => {
  const { className, children } = props;

  return (
    <thead
      className={mergeClassNames(
        'bg-transparent text-left font-semibold ',
        className
      )}
    >
      {children}
    </thead>
  );
};

const Body = (props: BasicProps) => {
  const { className, children } = props;

  return <tbody className={mergeClassNames('', className)}>{children}</tbody>;
};

interface RowProps extends BasicProps {
  isHeader?: boolean;
}

const Row = (props: RowProps) => {
  const { className, children, isHeader } = props;

  return (
    <tr
      className={mergeClassNames(
        'border-b',
        { 'hover:bg-white hover:bg-opacity-15': !isHeader },
        className
      )}
    >
      {children}
    </tr>
  );
};

export type SortOrder = 'asc' | 'desc' | null;

interface HeaderCellProps extends BasicProps {
  sortOrder?: SortOrder;
  onSort?: (order: SortOrder) => void;
}

const HeaderCell = (props: HeaderCellProps) => {
  const { className, children, sortOrder = null, onSort } = props;

  const clickHandler = () => {
    if (!onSort) return;

    let newOrder: SortOrder;

    switch (sortOrder) {
      case null:
        newOrder = 'desc';
        break;
      case 'desc':
        newOrder = 'asc';
        break;
      case 'asc':
        newOrder = null;
        break;
    }

    onSort(newOrder);
  };

  return (
    <th
      className={mergeClassNames(
        'text-left align-middle',
        {
          'cursor-pointer': !!onSort,
        },
        className
      )}
      onClick={clickHandler}
    >
      <div className="flex items-center select-none">
        {children}
        {!!onSort && (
          <span className="flex-col gap-0.5 ml-2 text-white">
            <SortIcon
              className={mergeClassNames({
                'text-customPrimary': sortOrder === 'asc',
              })}
            />
            <SortIcon
              className={mergeClassNames('rotate-180', {
                'text-customPrimary': sortOrder === 'desc',
              })}
            />
          </span>
        )}
      </div>
    </th>
  );
};

interface CellProps extends BasicProps {
  colSpan?: number;
  onClick?: () => void;
}

const Cell = (props: CellProps) => {
  const { className, colSpan, children, onClick } = props;

  return (
    <td
      colSpan={colSpan}
      className={mergeClassNames(
        {
          'underline cursor-pointer': !!onClick,
        },
        className
      )}
      onClick={onClick}
    >
      {children}
    </td>
  );
};

export interface PaginationProps {
  pageSize: number;
  currentPage: number;
  total?: number;
  shouldOnlyShowPages?: boolean;
  size?: 'small' | 'default';
  onPageSizeChange: (pageSize: number) => void;
  onCurrentPageChange: (currentPage: number) => void;
}

const Pagination = (props: PaginationProps) => {
  const {
    pageSize,
    total,
    currentPage,
    shouldOnlyShowPages = false,
    size = 'default',
    onPageSizeChange,
    onCurrentPageChange,
  } = props;

  const itemsPerPageOptions = [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];

  const recordsFrom =
    (currentPage - 1) * pageSize ? (currentPage - 1) * pageSize + 1 : 0;
  const recordsTo = Math.min(currentPage * pageSize, total || 0);
  const recordsOf = total || 0;

  useEffect(() => {
    const noOfPages = total && total / pageSize + (total % pageSize ? 1 : 0);

    if (noOfPages && currentPage > noOfPages) {
      onCurrentPageChange(Math.floor(noOfPages));
    }
  }, [total, pageSize, currentPage]);

  return (
    <div
      className={mergeClassNames(
        'w-full py-6 flex items-center justify-between custom-table-pagination',
        { 'justify-end': shouldOnlyShowPages, 'py-2': size === 'small' }
      )}
    >
      {!shouldOnlyShowPages && (
        <>
          <div className="flex items-center gap-4">
            <span className="text-sm text-customGrey min-w-max">
              Rows per page
            </span>
            <Select
              className="!w-16"
              showSearch={false}
              allowClear={false}
              value={pageSize}
              options={itemsPerPageOptions}
              onSelect={(value) => onPageSizeChange(value as number)}
            />
          </div>

          <div className="text-sm text-customGrey font-bold">
            {`${recordsFrom} - ${recordsTo} of ${recordsOf}`}
          </div>
        </>
      )}

      <PaginationInner
        className={mergeClassNames('text-customGrey', size)}
        showSizeChanger={false}
        current={currentPage}
        defaultCurrent={1}
        total={total}
        pageSize={pageSize}
        onChange={(page) => onCurrentPageChange(page)}
        prevIcon={
          <ArrowTailless className="rotate-90 h-2 w-2 text-customGrey" />
        }
        nextIcon={
          <ArrowTailless className="-rotate-90 h-2 w-2 text-customGrey" />
        }
      />
    </div>
  );
};

Table.Header = Header;
Table.Body = Body;
Table.Row = Row;
Table.HeaderCell = HeaderCell;
Table.Cell = Cell;

export default Table;
