import { MENTOR_ROLE } from '@shared/constants';
import { useAuthState } from '@shared/react';

import { StudentProfile } from './Profile';
import MentorSetting from './Profile/MentorSettings';
import StudentSetting from './Profile/StudentSettings';

const AccountPage = () => {
  const { roles } = useAuthState();
  const isMentor = roles.includes(MENTOR_ROLE);

  return isMentor ? <MentorSetting /> : <StudentProfile />;
};

export default AccountPage;
