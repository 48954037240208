import { Option } from '../MentorFeedbackModalSession';

export const showNext = (targetOptionsArray: Option[]) => {
  if (targetOptionsArray) {
    const found = targetOptionsArray?.find(
      (option: Option) => option.selected === true
    );
    return found ? true : false;
  }
  return false;
};

export const toggleSelectedCategory = (
  targetArray: any[],
  setTargetArray: any,
  index: number
) => {
  const prev = [...targetArray];
  prev[index].selected = !prev[index].selected;
  setTargetArray([...prev]);
};

export const regularModalMessage = (
  homeworkName: string,
  homeworkRate: string,
  engagementName: string,
  engagementRate: string,
  progressName: string,
  progressRate: string,
  goodThing1: string,
  goodThing2: string,
  goodThing3: string
) => {
  return `<div>
<div>
  <strong>Student homework Rate:</strong> ${homeworkRate}/5<br/>
  <strong>Student homework Explanation:</strong> ${homeworkName}
</div>
<br/>
<div>
  <strong>Student engagement Rate:</strong> ${engagementRate}/5<br/>
  <strong>Student engagement Explanation:</strong> ${engagementName}
</div>
<br/>
<div>
   <strong>Student progress Rate:</strong> ${progressRate}/5<br/>
   <strong>Student progress Explanation:</strong> ${progressName}
</div>
<br/>
<div>
   <strong>Topics Covered:</strong> ${goodThing1}
   <br/><br/>
   <strong>Topics to be Covered Next:</strong> ${goodThing2}
   <br/><br/>
   <strong>Anything the Parent Can Help With:</strong> ${goodThing3}
   <br/><br/>
</div>
</div>`;
};
