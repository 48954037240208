import Accordion from '@components/V4/Accordion';
import { MEETING_STATUSES, MeetingStatus } from '@shared/common';
import { MeetingWithDuration } from '@shared/types/DTOs/EnrollmentsDTO';
import moment from 'moment';
import MeetingDetail from './MeetingDetail';

interface SessionMeetingProps {
  meeting: MeetingWithDuration;
  name: string;
  isAccordionExpanded?: boolean;
}

interface AttendanceStatus {
  joinTime: string;
  leaveTime: string;
  duration: number;
  isWaitingRoom: boolean;
}

const convertSecondsToTime = (seconds?: number) => {
  if (!seconds) return undefined;
  const duration = moment.duration(seconds, 'seconds');
  return moment.utc(duration.asMilliseconds()).format('H[h] mm[m]');
};

const formatAttendance = (attendance: AttendanceStatus) => {
  return attendance.joinTime
    ? `${moment(attendance.joinTime).format('DD MMM, HH:mm')} - ${moment(
        attendance.leaveTime
      ).format('HH:mm')}`
    : 'n/a';
};

const getAttendanceTimes = (
  attendanceStatuses: AttendanceStatus[] | undefined
) => {
  if (!attendanceStatuses?.length) return 'n/a';
  return attendanceStatuses
    .filter((status) => !status.isWaitingRoom)
    .map(formatAttendance)
    .join('; ');
};

const SessionMeeting = ({
  meeting,
  name,
  isAccordionExpanded,
}: SessionMeetingProps) => {
  const mentorAttendanceTimes = getAttendanceTimes(
    meeting.mentor?.attendance?.status
  );
  const studentAttendanceTimes = getAttendanceTimes(
    meeting.students[0]?.attendance?.status
  );

  return (
    <Accordion
      title={
        <p className="font-raleway font-medium font-xs text-white">
          {name} Details{' '}
          <span className="font-bold">
            ({MEETING_STATUSES[meeting.status]})
          </span>
        </p>
      }
      defaultIsOpen={isAccordionExpanded}
      titleWrapperClassName="bg-surfaceHover p-2"
      className="flex m-2 max-h-[200px] laptop:max-h-[540px]"
    >
      <div className="flex flex-col gap-2">
        <div className="flex justify-between gap-4">
          <div className="flex flex-col gap-2 w-1/2">
            <MeetingDetail name="Meeting ID:" value={meeting._id} showCopyBtn />

            <MeetingDetail
              name="Meeting Creation Date:"
              value={
                meeting.createdAt
                  ? moment(meeting.createdAt).format('DD.MM.YYYY')
                  : 'n/a'
              }
            />

            <MeetingDetail
              name="Meeting Scheduled Date:"
              value={
                meeting.startDate
                  ? moment(meeting.startDate).format('DD.MM.YYYY, HH:mm')
                  : 'n/a'
              }
            />
            <MeetingDetail
              name="Meeting Status:"
              value={MEETING_STATUSES[meeting.status]}
            />
          </div>

          <div className="flex flex-col gap-2 w-1/2">
            <MeetingDetail
              name="Meeting Start:"
              value={
                meeting.startTime
                  ? moment(meeting.startTime).format('DD MMM, HH:mm')
                  : 'n/a'
              }
            />
            <MeetingDetail
              name="Meeting End:"
              value={
                meeting.endTime
                  ? moment(meeting.endTime).format('DD MMM, HH:mm')
                  : 'n/a'
              }
            />
            {!!meeting.duration && (
              <MeetingDetail
                name="Meeting Duration:"
                value={convertSecondsToTime(meeting.duration ?? 0)}
              />
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <MeetingDetail
            name="Mentor Joined:"
            duration={convertSecondsToTime(meeting.mentorTotalDuration)}
            value={mentorAttendanceTimes}
          />

          <MeetingDetail
            name="Student Joined:"
            duration={convertSecondsToTime(meeting.studentTotalDuration)}
            value={studentAttendanceTimes}
          />

          {meeting.status === MeetingStatus.NO_SHOW && (
            <MeetingDetail
              name="NoShow Reason:"
              value={meeting.endMeetingReason ?? 'n/a'}
            />
          )}
        </div>
      </div>
    </Accordion>
  );
};

export default SessionMeeting;
