import { ReactComponent as DefaultPfp } from '@assets/icons/V4/default-profile-pic.svg';
import { ReactComponent as UploadIcon } from '@assets/icons/V4/upload-icon.svg';
import { Enrollment } from '@shared/common';
import { useUploadProfilePicture } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import moment from 'moment';
import { ChangeEvent } from 'react';
import { useQueryClient } from 'react-query';

interface StudentAsideHeaderProps {
  name: string;
  profileImg: string;
  studentType: string;
  createdAt: string;
  enrollments: Partial<Enrollment>[];
  hasEditPermissions: boolean;
}
const StudentAsideHeader = (props: StudentAsideHeaderProps) => {
  const {
    name,
    profileImg,
    studentType,
    createdAt,
    enrollments,
    hasEditPermissions,
  } = props;

  const [showToast] = useToast();
  const query = useQueryClient();

  const { mutate: uploadImage } = useUploadProfilePicture({
    onSuccess: async () => {
      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated your profile.',
      });
      await query.invalidateQueries('/users/:id/profile');
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Profile image could not be saved.',
      });
    },
  });

  const imageChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('image', file);

      uploadImage(formData);
    }
  };

  const completedEnrollments = enrollments.filter(
    (enrollment) =>
      enrollment.ended === true && enrollment.course?.abbreviation?.trim()
  );

  const startDate = moment(createdAt);
  const endDate = moment();

  const years = endDate.diff(startDate, 'years');
  const months = endDate.diff(startDate.add(years, 'years'), 'months');

  const yearsOnAgora = years
    ? `${years} ${years > 1 ? 'years,' : 'year,'}`
    : '';

  const monthsOnAgora = months
    ? `${months} ${months > 1 ? 'months' : 'month'}`
    : '';

  const onAgoraSince = `${yearsOnAgora} ${monthsOnAgora}`;

  return (
    <div className="flex flex-col items-center gap-6">
      {hasEditPermissions ? (
        <div className="relative group rounded-full cursor-pointer">
          <input
            type="file"
            accept="image/*"
            className="absolute top-0 left-0 opacity-0 z-20 w-16 h-16 rounded-full"
            onChange={imageChangeHandler}
          />
          {profileImg.length ? (
            <img
              src={profileImg}
              className="rounded-full w-16 h-16 object-cover object-center"
              alt=""
              crossOrigin={'anonymous'}
            />
          ) : (
            <DefaultPfp className="rounded-full w-16 h-16" />
          )}
          <div className=" absolute z-10 top-0 left-0 flex items-center justify-center h-full w-full bg-black bg-opacity-65 rounded-full laptop:bg-transparent laptop:group-hover:bg-black laptop:group-hover:bg-opacity-65 ">
            <UploadIcon className="text-white laptop:hidden laptop:group-hover:flex" />
          </div>
        </div>
      ) : (
        <div>
          {profileImg.length ? (
            <img src={profileImg} className="rounded-full w-16 h-16" alt="" />
          ) : (
            <DefaultPfp className="rounded-full w-16 h-16" />
          )}
        </div>
      )}

      <div className="flex flex-col items-center gap-1">
        <h1 className="font-raleway text-xl font-bold leading-6">{name}</h1>
        <p className="text-xs font-semibold">{studentType}</p>
        <p className="text-xs leading-5 text-customGrey">
          {onAgoraSince}
          <span className="mx-0.5"> •</span> {completedEnrollments.length}
          {completedEnrollments.length !== 1 ? ' courses ' : ' course '}
          completed
        </p>
        <div className="flex gap-3 flex-wrap justify-center">
          {completedEnrollments.map((enrollment) => (
            <div key={enrollment._id} className=" bg-customGold rounded-lg">
              <p className="py-1 px-1 text-black font-bold">
                {enrollment.course?.abbreviation}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StudentAsideHeader;
