import styles from '@styles/components/mentor-feedback.module.scss';
import { FC } from 'react';

const SelectText: FC<{
  text: string;
  handleClick: () => void;
  isActive: boolean;
}> = ({ text, handleClick, isActive }) => (
  <div
    onClick={handleClick}
    className={`${styles.selectOptionsText} ${
      isActive
        ? styles.selectOptionsTextActive
        : styles.selectOptionsTextInactive
    }`}
  >
    {text}
  </div>
);

export default SelectText;
