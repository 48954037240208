import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import React from 'react';
import { ApplicationStatuses } from '../../../../types';

interface ApplicationStatusPillProps {
  status: ApplicationStatuses;
  className?: string;
}

const ApplicationStatusPill = (props: ApplicationStatusPillProps) => {
  const { status, className } = props;
  const statusPresets = {
    notStarted: {
      text: 'Application Not Started',
      color: 'bg-customRed',
    },
    inProgress: {
      text: 'Application In Progress',
      color: 'bg-customGold',
    },
    sent: {
      text: 'Application Sent',
      color: 'bg-customLila',
    },
    received: {
      text: 'Offer Received',
      color: 'bg-customLime',
    },
    accepted: {
      text: 'Offer Accepted',
      color: 'bg-customPrimary',
    },
    rejected: {
      text: 'Offer Rejected',
      color: 'bg-customGrey',
    },
  };

  return (
    <div
      className={mergeClassNames(
        'w-fit py-1 px-4 rounded-full',
        statusPresets[status]?.color,
        className
      )}
    >
      <p className="text-black text-xxs">{statusPresets[status]?.text}</p>
    </div>
  );
};

export default ApplicationStatusPill;
