import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import TextArea from '@components/V4/Inputs/Textarea';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { applicationStatuses, degreeTypes } from '@modules/Account/Profile';
import { useSaveUserProfile } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import moment from 'moment';
import { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import SessionModalFormWrapper from '../../../SessionModalFormWrapper';

interface UniversityGoalFormProps extends BasicProps {
  isModeAddNew?: boolean;
  studentId: string;
  goal?: any;
  onCloseEditForm: () => void;
}

const formDataSchema = z.object({
  name: z.string().min(1, { message: 'Please enter a valid exam name.' }),
  major: z.string().min(1, { message: 'Please enter a valid major name.' }),
  degree: z.string().min(1, { message: 'Please enter a valid degree type.' }),
  applicationDeadline: z
    .string()
    .min(1, { message: 'Please enter a valid date.' }),
  status: z.string().min(1, { message: 'Please enter a valid status type.' }),
  pros: z.string().optional().default(''),
  cons: z.string().optional().default(''),
  academicRequirements: z.string().optional().default(''),
});

type FormData = z.infer<typeof formDataSchema>;

const UniversityGoalForm = (props: UniversityGoalFormProps) => {
  const {
    isModeAddNew = false,
    studentId,
    goal,
    className,
    onCloseEditForm,
  } = props;

  const [showToast] = useToast();

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(
    studentId,
    {
      onSuccess: async () => {
        showToast({
          variant: 'success',
          messageTitle: 'Success',
          messageBody: 'Goals updated successfully.',
        });
        onCloseEditForm();
      },
      onError: () => {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: 'Goals failed to update.',
        });
      },
    }
  );

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      name: goal?.university.name,
      major: goal?.major.name,
      degree: goal?.degree,
      applicationDeadline: goal?.applicationDeadline ?? '',
      status: goal?.status,
      pros: goal?.pros ?? '',
      cons: goal?.cons ?? '',
      academicRequirements: goal?.academicRequirements,
    },
  });

  const { field: degreeField, fieldState: degreeFieldState } = useController({
    name: 'degree',
    control,
  });

  const { field: statusField, fieldState: statusFieldState } = useController({
    name: 'status',
    control,
  });

  const {
    field: applicationDeadlineField,
    fieldState: applicationDeadlineFieldState,
  } = useController({
    name: 'applicationDeadline',
    control,
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();

    if (isValid) {
      const universityData: any = {
        _id: goal?._id,
        university: {
          name: data.name,
          type: 'University',
        },
        major: {
          name: data.major,
        },
        applicationDeadline: data.applicationDeadline,
        academicRequirements: data.academicRequirements ?? '',
        degree: data.degree,
        pros: data.pros,
        cons: data.cons,
        status: data.status,
      };

      updateUserProfile({ appliedUniversities: [universityData] });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  return (
    <SessionModalFormWrapper
      contentClassName={className}
      title={isModeAddNew ? 'Add Goal' : 'Edit Goal'}
      isLoading={isLoading}
      onCancel={onCloseEditForm}
      submitHandler={submitHandler}
      submitButtonText="Create Goal"
    >
      <Input
        isRequired
        type="text"
        id="university"
        label="University"
        isTouched={touchedFields.name}
        isValid={!errors.name}
        errorText={errors.name?.message}
        autofocus
        {...register('name')}
      />
      <div className="flex gap-6">
        <Input
          isRequired
          type="text"
          id="major"
          label="Major"
          isTouched={touchedFields.major}
          isValid={!errors.major}
          errorText={errors.major?.message}
          {...register('major')}
        />
        <Select
          size="large"
          isRequired
          value={degreeField.value}
          onSelect={(value) => degreeField.onChange(value)}
          options={degreeTypes?.map((degree) => ({
            label: degree,
            value: degree,
          }))}
          onBlur={degreeField.onBlur}
          label="Degree"
          isValid={!degreeFieldState.error}
          isTouched={degreeFieldState.isTouched}
          errorText={degreeFieldState.error?.message}
        />
      </div>
      <div className="flex gap-6">
        <AgoraDatePicker
          isRequired
          value={
            applicationDeadlineField.value
              ? moment(applicationDeadlineField.value)
              : undefined
          }
          onChange={(value) =>
            applicationDeadlineField.onChange(
              moment(value).format('YYYY-MM-DD')
            )
          }
          onBlur={applicationDeadlineField.onBlur}
          allowClear={false}
          label="Application Deadline"
          isValid={!applicationDeadlineFieldState.error}
          isTouched={applicationDeadlineFieldState.isTouched}
          errorText={applicationDeadlineFieldState.error?.message}
        />
        <Select
          size="large"
          isRequired
          value={statusField.value}
          onSelect={(value) => statusField.onChange(value)}
          options={applicationStatuses?.map((status) => ({
            label: status.label,
            value: status.value,
          }))}
          onBlur={statusField.onBlur}
          label="Status"
          isValid={!statusFieldState.error}
          isTouched={statusFieldState.isTouched}
          errorText={statusFieldState.error?.message}
        />
      </div>
      <div className="flex gap-6">
        <Input type="text" id="pros" label="Pro's" {...register('pros')} />
        <Input type="text" id="cons" label="Con's" {...register('cons')} />
      </div>
      <TextArea
        id="academicRequirements"
        label="Academic Requirements"
        {...register('academicRequirements')}
      />
    </SessionModalFormWrapper>
  );
};

export default UniversityGoalForm;
