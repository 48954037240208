import style from '@styles/components/reusables/page.module.scss';
import { Typography } from 'antd';
import React from 'react';
import { COLORS } from '../../v3/global';

interface Props {
  title: string | React.ReactElement;
  classes?: string;
  sectionButton?: string | React.ReactElement;
}
const SectionTitle: React.FC<Props> = ({
  title,
  classes,
  sectionButton,
  children,
}) => (
  <div className={`${style.headingContainer} ${classes}`}>
    <Typography.Title
      style={{ color: COLORS.TEXT_PRIMARY }}
      level={2}
      className={style.heading}
    >
      {title}
    </Typography.Title>
    {children}
  </div>
);

export default SectionTitle;
