import { UserProfileDTO } from '@shared/types/DTOs/UserDTO';
import StudentAsideContact from './StudentAsideContact';
import StudentAsideHeader from './StudentAsideHeader';
import StudentAsideLocation from './StudentAsideLocation';
import StudentAsideParent from './StudentAsideParent';

interface StudentAsideProps {
  userProfile?: Partial<UserProfileDTO>;
  hasEditPermissions: boolean;
}
const StudentAside = (props: StudentAsideProps) => {
  const { userProfile, hasEditPermissions } = props;

  return (
    <aside className="flex flex-col gap-4 p-6 bg-surfaceBackground rounded-lg tablet:w-2/5 laptop:w-1/3">
      {userProfile && (
        <>
          <StudentAsideHeader
            name={userProfile.fullName ?? ''}
            profileImg={userProfile.avatar ?? ''}
            studentType={userProfile.studentType ?? ''}
            createdAt={userProfile.createdAt ?? ''}
            enrollments={userProfile.enrollments ?? []}
            hasEditPermissions={hasEditPermissions}
          />

          <StudentAsideContact
            email={userProfile.email ?? ''}
            phone={userProfile.phoneNumber ?? ''}
            userId={userProfile._id ?? ''}
            hasEditPermissions={hasEditPermissions}
          />
          <StudentAsideParent
            email={userProfile.parentData?.email ?? ''}
            phone={userProfile.parentData?.phoneNumber ?? ''}
            name={userProfile.parentData?.fullName ?? ''}
            userId={userProfile._id ?? ''}
            hasEditPermissions={hasEditPermissions}
          />
          <StudentAsideLocation
            location={`${userProfile.city} ${userProfile.country}`}
            city={userProfile.city ?? ''}
            country={userProfile.country ?? ''}
            timezone={userProfile.timezone ?? ''}
            language={userProfile.preferredLanguage ?? ''}
            userId={userProfile._id ?? ''}
            hasEditPermissions={hasEditPermissions}
          />
        </>
      )}
    </aside>
  );
};

export default StudentAside;
