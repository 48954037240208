import { useModal } from '@hooks';
import { Enrollment } from '@shared/common';
import styles from '@styles/components/table.module.scss';
import { Empty, Spin } from 'antd';
import { FC } from 'react';
import { COLORS } from '../../../../v3/global';
import StudentDetailsModal from '../StudentDetailsModal';
import StudentRow from './StudentRow';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';

type Props = {
  studentsEnrollments: Enrollment[] | undefined;
  isLoading: boolean;
  tableHeight?: number;
  refetch?: () => any;
};

const MyStudentsTable: FC<Props> = ({
  studentsEnrollments,
  isLoading,
  tableHeight,
  refetch,
}) => {
  const [DetailsModal, showDetailsModal] = useModal(StudentDetailsModal);

  return (
    <div>
      <div
        style={{
          backgroundColor: COLORS.BLACK,
          overflowY: 'hidden',
        }}
        className={`${styles.gridTable} ${
          isLoading ? styles.tableLoading : ''
        }`}
      >
        <div
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.TEXT_PRIMARY,
          }}
          className={styles.gridTableHeader}
        >
          Student
        </div>
        <div
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.TEXT_PRIMARY,
          }}
          className={styles.gridTableHeader}
        >
          Course
        </div>
        <div
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.TEXT_PRIMARY,
          }}
          className={styles.gridTableHeader}
        >
          Start Date
        </div>
        <div
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.TEXT_PRIMARY,
          }}
          className={styles.gridTableHeader}
        >
          Sessions Done
        </div>
        <div
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.TEXT_PRIMARY,
          }}
          className={styles.gridTableHeader}
        >
          Graduation Year
        </div>
        <div
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.TEXT_PRIMARY,
          }}
          className={styles.gridTableHeader}
        >
          Status
        </div>
        <div
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.TEXT_PRIMARY,
          }}
          className={styles.gridTableHeader}
        >
          Actions
        </div>

        {isLoading && <LoadingSpinner className="w-full" />}

        {studentsEnrollments?.length && !isLoading ? (
          studentsEnrollments?.map((studentEnrollment, index) => (
            <StudentRow
              isEven={index % 2 === 0}
              studentEnrollment={studentEnrollment}
              showDetailsModal={showDetailsModal}
              completedNumber={studentEnrollment.noOfCompletedSessions}
              key={`${studentEnrollment._id}-${index}`}
              refetch={refetch}
            />
          ))
        ) : (
          <div style={{ gridColumn: '1 / 8' }}>{!isLoading && <Empty />}</div>
        )}
      </div>
      {DetailsModal}
    </div>
  );
};

export default MyStudentsTable;
