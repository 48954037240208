import { ReactComponent as CameraIconActive } from '@assets/icons/video-icons/camera-icon-active.svg';
import { ReactComponent as CameraIcon } from '@assets/icons/video-icons/camera-icon.svg';
import DefaultAvatarIcon from '@assets/icons/video-icons/default-avatar.svg';
import { ReactComponent as MicrophoneIconActive } from '@assets/icons/video-icons/microphone-icon-active.svg';
import { ReactComponent as MicrophoneIcon } from '@assets/icons/video-icons/microphone-icon.svg';
import IconButton from '@components/V4/IconButton';
import Tooltip from '@components/V4/Tooltip';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { forwardRef } from 'react';

interface VideoWidgetProps {
  className?: string;
  videoClassName?: string;
  isMicrophoneActive?: boolean;
  isCameraActive: boolean;
  isMicrophoneDisabled: boolean;
  isCameraDisabled: boolean;
  avatarUrl?: string;
  onMicrophoneClick?: () => void;
  onCameraClick?: () => void;
}

const VideoWidget = forwardRef((props: VideoWidgetProps, ref: any) => {
  const {
    className,
    videoClassName,
    isMicrophoneActive,
    isCameraActive,
    isMicrophoneDisabled,
    isCameraDisabled,
    avatarUrl,
    onMicrophoneClick,
    onCameraClick,
  } = props;

  return (
    <div className={mergeClassNames('relative bg-black', className)}>
      <video
        className={mergeClassNames(
          'w-full aspect-video bg-customGrey tablet:rounded-lg laptop:max-h-full -scale-x-100',
          videoClassName
        )}
        muted={true}
        ref={ref}
        playsInline
        autoPlay
      />
      {(isCameraDisabled || !isCameraActive) && (
        <div className="absolute z-50 flex items-center justify-center bg-surfaceObject w-full h-full top-0 left-0 tablet:rounded-lg">
          <img
            className="w-14 h-14 rounded-full"
            src={avatarUrl || DefaultAvatarIcon}
            alt="Person"
            crossOrigin={'anonymous'}
          />
        </div>
      )}
      <div className="absolute z-1000 bottom-5 left-1/2 -translate-x-1/2 flex gap-6">
        {!!onMicrophoneClick && (
          <Tooltip
            text={isMicrophoneActive ? 'Mute microphone' : 'Unmute microphone'}
            placement="top"
          >
            <IconButton
              variant="secondary"
              size="large"
              icon={
                isMicrophoneActive ? (
                  <MicrophoneIcon />
                ) : (
                  <MicrophoneIconActive />
                )
              }
              onClick={() => onMicrophoneClick()}
              isActive={!isMicrophoneActive}
              color="danger"
              isDisabled={isMicrophoneDisabled}
            />
          </Tooltip>
        )}
        {!!onCameraClick && (
          <Tooltip
            text={isCameraActive ? 'Pause camera' : 'Turn on camera'}
            placement="top"
          >
            <IconButton
              variant="secondary"
              size="large"
              icon={
                isCameraActive ? (
                  <CameraIcon className="" />
                ) : (
                  <CameraIconActive />
                )
              }
              onClick={() => onCameraClick()}
              isActive={!isCameraActive}
              color="danger"
              isDisabled={isCameraDisabled}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
});

export default VideoWidget;
