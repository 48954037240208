import { ReactComponent as SendIcon } from '@assets/icons/arrow.svg';
import { ReactComponent as ArrowIcon } from '@assets/icons/arrow.svg';
import IconButton from '@components/V4/IconButton';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import useObserveElement from 'apps/agora/src/hooks/useObserveElement';
import useToast from 'apps/agora/src/hooks/useToast';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import moment from 'moment';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { scrollToLastElement } from './helpers';

export interface ChatMessage {
  author: string;
  timeStamp: number;
  message: string;
}

interface SidebarChatPageProps {
  chatMessages: ChatMessage[];
  unreadCount: number;
  onUnreadCountChange: Dispatch<SetStateAction<number>>;
}

const SidebarChatPage = (props: SidebarChatPageProps) => {
  const { chatMessages, unreadCount, onUnreadCountChange } = props;
  const { zoomClient } = useContext(ZoomContext);
  const [newMessage, setNewMessage] = useState('');
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

  const chatClient = zoomClient?.getChatClient();

  const containerRef = useObserveElement({
    id: 'last-scrolled-to-child',
    onIntersect: () => setShouldAutoScroll(true),
  });

  const [showToast] = useToast();

  // Event handler function
  const scrollToLastMessageHandler: React.MouseEventHandler<
    HTMLDivElement
  > = () => scrollToLastElement(containerRef.current);

  const sendMessageHandler = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (!newMessage.trim() || !chatClient) return;

    try {
      await chatClient.sendToAll(newMessage);

      setNewMessage('');
      scrollToLastElement(containerRef.current, 'instant');
    } catch (error: any) {
      let errorToShow;

      switch (error.code) {
        case 'IMPROPER_MEETING_STATE':
          errorToShow = `Please make sure that you are in a meeting. (${error})`;
          break;

        case 'INSUFFICIENT_PRIVILEGES':
          errorToShow = `You are not allowed to send messages. (${error})`;
          break;

        case 'INVALID_PARAMETERS':
          errorToShow = `Invalid parameters. (${error})`;
          break;

        default:
          errorToShow = error;
          break;
      }

      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: `Message could not be sent. ${errorToShow}`,
      });
    }
  };

  useEffect(() => {
    if (chatMessages && shouldAutoScroll) {
      scrollToLastElement(containerRef.current, 'instant');
    }
  }, [shouldAutoScroll, chatMessages]);

  // event listener on scroll
  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const handleScroll = () => {
      const sensibility = 20;
      const isAtBottom =
        container.scrollHeight - container.scrollTop - sensibility <=
        container.clientHeight;

      // Disable auto-scroll when the user scrolls away from the bottom
      if (!isAtBottom) {
        setShouldAutoScroll(false);
      } else {
        // Re-enable auto-scroll when the user scrolls to the bottom manually
        setShouldAutoScroll(true);
        onUnreadCountChange(0); // if we are here it means that we are at the bottom of the message container, thus there should be no unread messages
      }
    };

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="relative flex flex-auto flex-col justify-between gap-6 text-white">
      <div className="p-2 bg-surfaceHover rounded-xl">
        <p className="text-xxs font-semibold">
          Messages will remain accessible to participants even after the meeting
          ends.
        </p>
      </div>

      <div
        ref={containerRef}
        className=" flex flex-col gap-6 h-full max-h-full overflow-y-auto"
      >
        {chatMessages?.map((message, index) => (
          <div key={index} className="flex flex-col gap-2">
            <div className="flex gap-2 text-customGrey">
              <h3 className="text-inherit text-xs font-bold">
                {message.author}
              </h3>
              <h4 className="text-inherit text-xs font-light">
                {moment(message.timeStamp).format('HH:mm')}
              </h4>
            </div>
            <p className="text-xs">{message.message}</p>
          </div>
        ))}
      </div>

      {!shouldAutoScroll && (
        <div
          onClick={scrollToLastMessageHandler}
          className="absolute cursor-pointer top-14 right-5 "
        >
          <IconButton
            variant="secondary"
            size="large"
            icon={<ArrowIcon className="rotate-180" />}
            className="relative bg-surfaceHover"
          />
          {unreadCount !== 0 && (
            <p className="absolute top-0 right-0 bg-customRed rounded-full text-xxs w-5 h-5 text-center p-[3px]">
              {unreadCount}
            </p>
          )}
        </div>
      )}

      <form
        onSubmit={sendMessageHandler}
        className="flex items-center bg-surfaceHover py-2 px-6 w-full rounded-5"
      >
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          className="bg-transparent border-none w-full h-6 p-0 text-xs placeholder:text-xs placeholder:text-customGrey"
          placeholder="Send a message"
          autoFocus
        />
        <IconButton
          onClick={sendMessageHandler}
          type="submit"
          variant="secondary"
          icon={<SendIcon className="fill-black" />}
          className={mergeClassNames('bg-customGrey text-black', {
            'bg-white': !!newMessage,
          })}
        />
      </form>
    </div>
  );
};

export default SidebarChatPage;
